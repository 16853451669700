import * as React from "react";
import {
    Edit,
    Show,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceManyField,
    Datagrid,
    TextField, FunctionField,
    DateField,
    NumberField,
    minLength, maxLength,
    List,
    Filter,
    TopToolbar,
    ListButton,
    ShowButton,
    EditButton,
    useRecordContext, SelectField, ReferenceField
} from 'react-admin';
import { countries, legalForms } from './enums';
import { DynamicUrlField } from '../components/UrlField';
import AddAddressButton from "../components/AddAddressButton";
import AddEmailButton from "../components/AddEmailButton";
import AddPhoneNumberButton from "../components/AddPhoneNumbersButton";
import AddSystemButton from "../components/AddSystemButton";
import {makeStyles} from "@mui/styles";
import SoftDeleteButton from "../components/SoftDeleteButton";
import OpusPagination from "../components/OpusPagination";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";
import MyForm from "../components/UidInput";


const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CrmOrganisationTitle = () => {
    const record = useRecordContext();

    return <span>Organisation {record ? `#${record.originId} - ${record.legal_name} ` : ''}</span>;
};

const CrmOrganisationFilter = (props) => (
    <Filter {...props}>
        <NumberInput source="id" alwaysOn />
        <TextInput label="Legal Name" source="legal_name" alwaysOn />
    </Filter>
)

const CrmOrganisationShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <EditButton />
    </TopToolbar>
);

const CrmOrganisationEditActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <ShowButton />
    </TopToolbar>
);

export const CrmOrganisationList = props => (
    <List
        filters={<CrmOrganisationFilter />}
        title="Organisationen"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="legal_name" headerClassName={useStyles().header} />
            <SelectField source="legal_form" choices={legalForms} label="Rechtsform" sortable={false} headerClassName={useStyles().header} />
            <TextField source="uid" headerClassName={useStyles().header} />
            <SelectField source="country" choices={countries} headerClassName={useStyles().header} />
            <ReferenceField
                reference="crm_addresses"
                source="current_billing_address"
                label="Addresszeile 1"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="address_line1"  />
            </ReferenceField>
            <ReferenceField
                reference="crm_addresses"
                source="current_billing_address"
                label="Addresszeile 2"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="address_line2"  />
            </ReferenceField>
            <ReferenceField
                reference="crm_addresses"
                source="current_billing_address"
                label="PLZ"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="zip"  />
            </ReferenceField>
            <ReferenceField
                reference="crm_addresses"
                source="current_billing_address"
                label="Ort"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="city"  />
            </ReferenceField>
            <DateField source="created" headerClassName={useStyles().header} />
            <DateField source="modified" headerClassName={useStyles().header} />
            <EditButton/> 
        </Datagrid>
    </List>
);

export const CrmOrganisationCreate = (props) => MyForm(props)


    export const CrmOrganisationShow = ( props) => (
    <Show {...props} actions={<CrmOrganisationShowActions />} title={<CrmOrganisationTitle />}>
        <SimpleShowLayout>
            <TextField source="legal_name" label="Firma" />
            <TextField source="country" label="Land"/>
            <SelectField source="legal_form" label="Rechtsform" choices={legalForms} />
            <TextField source="uid" />
            <ReferenceField reference="crm_addresses" source="current_billing_address" label="Aktuelle Rechnungsadresse" link={false} >
                <TextField source="address_line1" />{', '}
                <TextField source="address_line2" />{', '}
                <TextField source="zip" />{', '}
                <TextField source="city" />{', '}
                <TextField source="country" />
            </ReferenceField>
            <ReferenceField reference="crm_addresses" source="current_billing_address" label="Zustellbarkeit geprüft" link={false} >
                <TextField source="is_deliverable" />{', '}<DateField source="date_of_last_check" />
            </ReferenceField>
            <ReferenceField reference="crm_emails" source="current_billing_email" link={false}>
                <TextField source="email" />
            </ReferenceField>
            <TextField source="invoice_preference" label="Rechnungsversand" />
            <ReferenceManyField
                reference="crm_addresses"
                target="crm_entity"
                source="crm_entity"
                label="Adressen"
                sort={{field: 'id', order:'asc'}}>
                <Datagrid bulkActionButtons={false} >
                    <TextField source="category" />
                    <TextField source="address_line1" />
                    <TextField source="address_line2" />
                    <TextField source="zip" />
                    <TextField source="city" />
                    <TextField source="canton" />
                    <TextField source="country" />
                    <TextField source="source" />
                    <TextField source="is_deliverable" label="zustellbar" />
                    <TextField source="date_of_last_check" label="letzte Prüfung" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddAddressButton crmEntityId={record.crm_entity} />}/>
            <ReferenceManyField
                reference="crm_phone_numbers"
                target="crm_entity"
                source="crm_entity"
                label="phone"
                sort={{field: 'id', order:'asc'}}
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="category" />
                    <TextField source="source" />
                    <TextField source="phone_number" label="Telefonnummer" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddPhoneNumberButton crmEntityId={record.crm_entity} />}/>
            <ReferenceManyField
                reference="crm_emails"
                target="crm_entity"
                source="crm_entity"
                label="email"
                sort={{field: 'id', order:'asc'}}
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="category" />
                    <TextField source="source" />
                    <TextField source="email" label="Email" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddEmailButton crmEntityId={record.crm_entity} />}/>
            <ReferenceManyField
                reference="crm_systems"
                source="crm_entity"
                target="crm_entity"
                label="andere Systeme"
                sort={{field: 'systemName', order:'asc'}}
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="system_name" />
                    <FunctionField label="SystemId" render={record => DynamicUrlField(record.system_name, record.system_id)}/>
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddSystemButton crmEntityId={record.crm_entity} />}/>
            <ReferenceManyField
                reference="crm_contract_relationship_views"
                target="crm_organisation"
                source="id"
                label="Vertragsbeziehungen"
                sort={{field: 'id', order:'asc'}}
            >
                <Datagrid rowClick="show" bulkActionButtons={false} >
                    <FunctionField label="Vertragsbeziehung" render={record => CrmContractRelationshipIdField(record.originId)} sortable={false} />
                    <TextField source="venue" label="Venue"/>
                    <TextField source="city" label="Ort" />
                </Datagrid>
            </ReferenceManyField>
            <DateField source="created" />
            <DateField source="modified" />
        </SimpleShowLayout>
    </Show>
)

export const CrmOrganisationEdit = (props) => {
    const transform = data => ({
        legal_name: data.legal_name,
        country: data.country,
        legal_form: data.legal_form['@id'],
        invoice_preference: data.invoice_preference,
        uid: data.uid,
    });

    return (
        <Edit mutationMode="pessimistic" transform={transform} {...props} actions={<CrmOrganisationEditActions />} title={<CrmOrganisationTitle />}>
            <SimpleForm >
                <TextInput source="legal_name" label="Firma" fullWidth/>
                <SelectInput source="country" label="Land" choices={countries} fullWidth/>
                <TextInput source="uid" parse={v => v.replace(".","").replace(".","").replace("-","")} validate={[minLength(12),maxLength(12)]} fullWidth/>
                <SelectInput source="legal_form.@id" label="Rechtsform" choices={legalForms} fullWidth/>
                <SelectInput source="invoice_preference" label="Rechnungsversand" choices={[{id: "email", name: "email"}, {id: "letter", name: "letter"}]} fullWidth/>
            </SimpleForm>
        </Edit>
    )
}
