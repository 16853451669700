import * as React from "react";
import {
    TextInput,
    Datagrid,
    TextField,
    DateField,
    List,
    Filter,
    NumberField,
    FunctionField,
    Show,
    SimpleShowLayout,
    useRecordContext,
    TopToolbar,
    ListButton,
    SelectInput,
    ReferenceField,
    ReferenceManyField,
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";
import FileDownload from "../components/FileDownload";
import {Grid} from "@mui/material";
import PingenSendButton from "../components/PingenSendButton";
import BexioSendButton from "../components/BexioSendButton";

const CtrInvoiceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Rechnungsnummer" source="invoice_nr" alwaysOn />
        <SelectInput label="Source" source="system_name" choices={[{"id":"bexio","name":"Bexio"},{"id":"skip5","name":"Skip5"}]} alwaysOn />
        <TextInput label="Vertragsbeziehung" source="crm_contract_relationship.id" alwaysOn />
        <SelectInput label="Währung" source={"currency"} choices={[{"id": "CHF","name":"CHF"},{"id": "EUR","name":"EUR"}]} alwaysOn />
    </Filter>
)

const formatDate = datestring => {
    let date = new Date(datestring)
    return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear()
}

const CtrInvoiceShowActions = () => (
    <TopToolbar>
        <FunctionField
            render={record => record.sendable
                ? ('email' === record.invoice_preference ? <BexioSendButton/> : <PingenSendButton/>) : null} />
        <ListButton label="Back" />
    </TopToolbar>
);

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    },
    send_headers: {

    }
});

const CtrInvoiceTitle = () => {
    const record = useRecordContext();

    return <span> {record ? `#${record.invoice_nr} - ${record.crm_contract_relationship.replace("/api/crm_contract_relationships/","")} - ${record.address} - ${record.status} - ${record.currency}` : ''}</span>;
};

export const CtrInvoiceList = props => (
    <List
        filters={<CtrInvoiceFilter />}
        title="Rechungen"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
        sort={{ field: 'invoice_nr', order: 'DESC'}}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <TextField label="Nr" source="invoice_nr" headerClassName={useStyles().header} />
            <TextField label="Source" source="system_name" headerClassName={useStyles().header} />
            <ReferenceField source="crm_contract_relationship" label="Vertragsbeziehung" headerClassName={useStyles().header} link={(record) => '/crm_contract_relationship_views/'+encodeURIComponent(record.crm_contract_relationship.replace("relationships","relationship_views"))+'/show'} >
                <NumberField source="originId"  headerClassName={useStyles().header} sortable={false} />{' - '}
                <ReferenceField source="crm_venue" ><TextField source="name"/></ReferenceField>{' - '}
                <ReferenceField source="crm_organisation" ><TextField source="legal_name"/></ReferenceField>
            </ReferenceField>
            <TextField label="Rechnungsadresse" source="address" headerClassName={useStyles().header} sortable={false} />
            <ReferenceField source="crm_contract_relationship" label="Email" headerClassName={useStyles().header} link={(record) => '/crm_contract_relationship_views/'+encodeURIComponent(record.crm_contract_relationship.replace("relationships","relationship_views"))+'/show'} >
                <ReferenceField reference="crm_organisations" source="crm_organisation" label="Aktuelle Rechnungsemal" link={false}>
                    <ReferenceField reference="crm_emails" source="current_billing_email" link={false}>
                        <TextField source="email" />
                    </ReferenceField>
                </ReferenceField>
            </ReferenceField>
            <DateField label="Rg Datum" source="invoice_date" headerClassName={useStyles().header}  />
            <DateField label="Fälligkeit" source="pay_by_date" headerClassName={useStyles().header} />
            <TextField label="Whr" source="currency" headerClassName={useStyles().header} />
            <FunctionField label="Netto" render={record => (record.net_amount/100).toFixed(2)} headerClassName={useStyles().header} />
            <FunctionField label="Brutto" render={record => (record.total_amount/100).toFixed(2)} headerClassName={useStyles().header} />
            <TextField source="status" headerClassName={useStyles().header} sortable={false} />
            <FunctionField label="Bezahlt" render={record => (record.paid_amount/100).toFixed(2)} headerClassName={useStyles().header} />
            <FunctionField label="Brutto" render={record => (record.open_amount/100).toFixed(2)} headerClassName={useStyles().header} />
            <FunctionField
                render={record => <FileDownload mediaObject={record.invoice_pdf_url} fileName={record.invoice_nr + '.pdf'} label="pdf" />}
                headerClassName={useStyles().header}
                label="PDF"
            />
            <FunctionField
                render={record => record.sendable
                    ? ('email' === record.invoice_preference ? <BexioSendButton/> : <PingenSendButton/> )
                    : <ReferenceField
                        reference="ctr_invoice_send_infos"
                        source="current_ctr_invoice_send_info"
                        emptyText="n.a."
                        link={false}
                    >
                        <TextField source="status" />{' - '}<DateField source='modified' />
                    </ReferenceField>}
                headerClassName={useStyles().header}
                label="Versand"
            />
        </Datagrid>
    </List>
);

export const CtrInvoiceShow = props => (
    <Show
        actions={<CtrInvoiceShowActions/>}
        title={<CtrInvoiceTitle />}
        hasCreate={false}
        {...props}
    >
        <Grid container spacing={2} sx={{margin: 2}}>
             <Grid item xs={6} >
                <SimpleShowLayout>
                    <ReferenceField source="crm_contract_relationship"  label="Vertragsbeziehung" headerClassName={useStyles().header} link={(record) => '/crm_contract_relationship_views/'+encodeURIComponent(record.crm_contract_relationship.replace("relationships","relationship_views"))+'/show'} >
                        <NumberField source="originId"  headerClassName={useStyles().header} sortable={false} />{' - '}
                        <ReferenceField source="crm_venue" ><TextField source="name"/></ReferenceField>{' - '}
                        <ReferenceField source="crm_organisation" ><TextField source="legal_name"/></ReferenceField>
                    </ReferenceField>
                    <TextField label="Rechnungsadresse" source="address" headerClassName={useStyles().header} sortable={false} />
                    <FunctionField label="Datum" render={record => formatDate(record.invoice_date) + ' - zahlbar bis ' + formatDate(record.pay_by_date)} headerClassName={useStyles().header} />
                    <FunctionField label="Netto" render={record => record.currency + ' ' + (record.net_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                    <FunctionField label="Brutto" render={record => record.currency + ' ' + (record.total_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
                <SimpleShowLayout>
                    <TextField source="status" headerClassName={useStyles().header} sortable={false} />
                    <FunctionField label="Offen" render={record => (record.open_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                    <FunctionField label="Gutschrift" render={record => (record.credited_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                    <FunctionField label="Bezahlt" render={record => (record.paid_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
                <SimpleShowLayout>
                    <FunctionField
                        render={record => record.invoice_pdf_url ? <FileDownload mediaObject={record.invoice_pdf_url} fileName={record.invoice_nr + '.pdf'} label="pdf" /> : 'not available'}
                        headerClassName={useStyles().header}
                        label="PDF"
                    />
                    <ReferenceField
                        reference="ctr_invoice_send_infos"
                        source="current_ctr_invoice_send_info"
                        label="Versandstatus"
                        emptyText="not available"
                        link={false}
                    >
                        <TextField source="status" />{' - '}<DateField source='modified' />
                    </ReferenceField>
                    <ReferenceField
                        reference="ctr_invoice_send_infos"
                        source="current_ctr_invoice_send_info"
                        label="Versandart"
                        emptyText="not available"
                        link={false}
                    >
                        <TextField source="delivery_product" />
                    </ReferenceField>
                    <ReferenceField
                        reference="ctr_invoice_send_infos"
                        source="current_ctr_invoice_send_info"
                        label="Versandkanal"
                        emptyText="not available"
                        link={false}
                    >
                        <TextField source="system_name" />
                    </ReferenceField>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12}>
                <SimpleShowLayout>
                    <ReferenceManyField target="ctr_invoice" reference="ctr_invoice_items" source="id" headerClassName={useStyles().header}>
                        <Datagrid bulkActionButtons={false} >
                            <TextField source="description" headerClassName={useStyles().header} />
                            <DateField label="Von" source="start_date" headerClassName={useStyles().header} />
                            <DateField slabel="" source="end_date" headerClassName={useStyles().header} />
                            <NumberField label="Anzahl" source="quantity" headerClassName={useStyles().header} />
                            <TextField label="Eiheit" source="unit" headerClassName={useStyles().header} />
                            <ReferenceField
                                reference="ctr_invoices"
                                source="ctr_invoice"
                                label="Whg"
                                headerClassName={useStyles().header}
                                link={false}
                            >
                                <TextField source="currency"/>
                            </ReferenceField>
                            <FunctionField label="Preis" render={record => (record.price/100).toFixed(2)} headerClassName={useStyles().header} />
                            <FunctionField label="Rabatt" render={record => (record.rebate_absolute/100).toFixed(2)} headerClassName={useStyles().header} />
                            <FunctionField label="rebate_percent" render={record => (record.rebate_percent/1000000).toFixed(2)} headerClassName={useStyles().header} />
                            <FunctionField label="amount" render={record => (record.amount/100).toFixed(2)} headerClassName={useStyles().header} />
                        </Datagrid>
                    </ReferenceManyField>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12} >
                <SimpleShowLayout>
                    <ReferenceManyField
                        label="send events"
                        target="ctr_invoice"
                        reference="ctr_invoice_send_infos"
                        source="id"
                        sort={{ field: 'modified', order: 'DESC' }}
                    >
                        <Datagrid bulkActionButtons={false} >
                            <TextField source="system_name" sx={{ color: 'lightblue' }}/>
                            <TextField source="status" />
                            <TextField source="delivery_product" />
                            <FunctionField label="Preis" render={record => (record.price/100).toFixed(2)} />
                            <TextField source="currency" />
                            <DateField source="created" />
                            <DateField source="modified" />
                        </Datagrid>
                    </ReferenceManyField>
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

