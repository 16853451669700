import { Button, useRefresh, useNotify } from "react-admin";
import processProvider from "../utils/processProvider";
import DeleteIcon from '@mui/icons-material/Delete';

const SoftDeleteButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const handleClick = () => {
        processProvider.softDelete(record)
            .then(() => {
                notify('Success: element marked as deleted.');
            })
            .then(() => { refresh(); })
            .catch(() => {
                notify('Error: element could not be marked as deleted.', 'warning')
            });
    }

    return !record.valid_until &&  <Button onClick={handleClick} startIcon={<DeleteIcon />}></Button>
};

export default SoftDeleteButton;