import * as React from "react";
import {
    TextInput,
    SimpleShowLayout,
    NumberField,
    Show,
    Datagrid,
    TextField,
    DateField,
    List,
    Filter,
    FunctionField,
    TopToolbar,
    ListButton,
    useRecordContext,
    ReferenceField,
    ReferenceManyField,
    SelectInput, downloadCSV, Button, CreateButton
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";
import OpusPagination from "../components/OpusPagination";
import ApproveButton from "../components/ApproveButton";
import {ctrCurrency} from "./enums";
import processProvider from "../utils/processProvider";
import PrepareRenewalsAndInstallmentsButton from "../components/PrepareRenewalsAndInstallmentsButton";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const ExportButton = () => {
    const handleClick = () => processProvider.exportMeteredInvoices().then((csv) => downloadCSV(csv, 'metered_invoices'))

    return <Button onClick={handleClick}>Export</Button>
}

const CtrStageMeteredInvoiceTitle = () => {
    const record = useRecordContext();

    return <span>Metered Rechnungsentwurf {record ? `#${record.originId}` : '' }</span>
};

const ApproveMeteredInvoiceButton = () => <ApproveButton entity="ctr_stage_metered_invoice" action="invoice_usage" />

const CtrStageMeteredInvoiceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Vertragsbeziehung" source="crm_contract_relationship.id" alwaysOn  />
        <TextInput label="Venue" source="crm_contract_relationship.crm_venue.name" alwaysOn />
        <TextInput label="Firma" source="crm_contract_relationship.crm_organisation.legal_name" alwaysOn />
        <TextInput label="Gastrogruppe" source="crm_contract_relationship.crm_group.name" alwaysOn />
        <SelectInput label="Währung" source="currency" choices={ctrCurrency} alwaysOn />
    </Filter>
);

const CtrStageMeteredInvoiceShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <ApproveMeteredInvoiceButton/>
    </TopToolbar>
);

const CtrStageMeteredInvoiceListActions = () => (
    <TopToolbar>
        <ExportButton />
    </TopToolbar>
);

export const CtrStageMeteredInvoiceList = (props) => (
    <List
        actions={<CtrStageMeteredInvoiceListActions/>}
        filters={<CtrStageMeteredInvoiceFilter />}
        filter={{ 'deleted[after]': '2200-12-31' }}
        title="Metered Rechnungsentwurf"
        pagination={<OpusPagination />}
        perPage={10}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <ReferenceField
                reference="crm_contract_relationships"
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.id"
                headerClassName={useStyles().header}
                label="Vertragsbeziehung"
                link={false}
            >
                <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
            </ReferenceField>
            <ReferenceField
                reference="crm_contract_relationships"
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.crm_venue.name"
                headerClassName={useStyles().header}
                label="Venue" link={false}
            >
                <ReferenceField reference="crm_venues" source="crm_venue" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField
                reference="crm_contract_relationships"
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.crm_organisation.legal_name"
                headerClassName={useStyles().header}
                label="Organisation"
                link={false}
            >
                <ReferenceField reference="crm_organisations" source="crm_organisation" link={false}>
                    <TextField source="legal_name"/>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField
                reference="crm_contract_relationships"
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.crm_group.name"
                headerClassName={useStyles().header}
                label="Gastrogruppe"
                link={false}
            >
                <FunctionField render={record => record.crm_group && <ReferenceField reference="crm_groups" source="crm_group" link={false}><TextField source="name"/></ReferenceField>} />
            </ReferenceField>
            <TextField source="currency" headerClassName={useStyles().header} />
            <FunctionField render={record => (record.total / 100).toFixed(2)} label="Total" headerClassName={useStyles().header} />
            <DateField source="prepared" headerClassName={useStyles().header} />
            <DateField source="created" headerClassName={useStyles().header} />
            <DateField source="modified" headerClassName={useStyles().header} />
            <ApproveMeteredInvoiceButton/>
        </Datagrid>
    </List>
);

export const CtrStageMeteredInvoiceShow = () => (
    <Show
        title={<CtrStageMeteredInvoiceTitle />}
        actions={<CtrStageMeteredInvoiceShowActions/>}
    >
        <SimpleShowLayout title="Ratenrechnungen">
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} label="Vertragsbeziehung" link={false} >
                <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} label="venue" link={false} >
                <ReferenceField reference="crm_venues" source="crm_venue" link={false}><TextField source="name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} label="Organisation" link={false} >
                <ReferenceField reference="crm_organisations" source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} label="Gastrogruppe" link={false} >
                <FunctionField render={record => record.crm_group && <ReferenceField reference="crm_groups" source="crm_group" link={false}><TextField source="name"/></ReferenceField>} />
            </ReferenceField>
            <ReferenceManyField
                reference="ctr_stage_metered_invoice_items"
                target="ctr_stage_metered_invoice"
                source="id"
                sort={{ field: 'item_type', order: 'DESC' }}
                label="Positionen"
                headerClassName={useStyles().header}
                link={false}
            >
                <Datagrid bulkActionButtons={false}>
                    <DateField source="billing_month" />
                    <ReferenceField reference="ctr_subscriptions" source="ctr_subscription" label="Subscription" >
                        <NumberField source="originId" />
                    </ReferenceField>
                    <TextField source="item_type" />
                    <NumberField source="account" />
                    <FunctionField render={record => (record.price_per_unit / 100).toFixed(2)} label="Preis" />
                    <NumberField source="amount" />
                    <NumberField source="threshold_amount" />
                    <NumberField source="invoice_amount" />
                    <FunctionField render={record => (record.total / 100).toFixed(2)} label="total_net"/>
                    <DateField source="created" />
                    <DateField source="modified" />
                </Datagrid>
            </ReferenceManyField>
            <TextField source="currency" />
            <FunctionField render={record => (record.total / 100).toFixed(2)} label="Total" />
            <DateField source="created" headerClassName={useStyles().header} />
            <DateField source="modified" headerClassName={useStyles().header} />
        </SimpleShowLayout>
    </Show>
);
