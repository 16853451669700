import fileProvider from "../utils/fileProvider"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const FileDownload = ({mediaObject, fileName}) => {
    console.log(mediaObject)
    if (!mediaObject) {
        return null
    }
    const handleClick = (e) => {
        e.stopPropagation()
        fileProvider.getFile(mediaObject, fileName)
    }

    return (
        <a
            role='button'
            onClick={handleClick}
            download={fileName}
        >
            <PictureAsPdfIcon/>
        </a>
    )
};

export default FileDownload;