export const countries = [
    { id:"CHE", name: "Schweiz" },
    { id: "AUT", name: "Österreich" },
    { id: "DEU", name: "Deutschland" },
    { id: "USA", name: "USA" },
    { id: "LIE", name: "Liechtenstein" },
    { id: "FRA", name: "Frankreich" },
    { id: "GBR", name: "Grossbritannien"},
]

export const units = [
    { id:"year", name:"year"},
    { id: "semester", name: "semester" },
    { id:"month", name:"month"},
    { id:"hour", name:"hour"},
    { id:"item", name:"item"},
    { id:"metered", name:"metered"},
]

export const legalForms = [
    { id: "/api/conf_legal_forms/0101", name: "Einzelunternehmen"},
    { id: "/api/conf_legal_forms/0103", name: "Kollektivgesellschaft"},
    { id: "/api/conf_legal_forms/0104", name: "Kommanditgesellschaft"},
    { id: "/api/conf_legal_forms/0105", name: "Kommanditaktiengesellschaft"},
    { id: "/api/conf_legal_forms/0106", name: "AG"},
    { id: "/api/conf_legal_forms/0107", name: "GmbH"},
    { id: "/api/conf_legal_forms/0108", name: "Genossenschaft"},
    { id: "/api/conf_legal_forms/0109", name: "Verein"},
    { id: "/api/conf_legal_forms/0110", name: "Stiftung"},
    { id: "/api/conf_legal_forms/0111", name: "Ausländische Niederlassung im Handelsregister eingetragen"},
    { id: "/api/conf_legal_forms/0113", name: "Besondere Rechtsformen, die unter keiner anderen Kategorie aufgeführt werden können."},
    { id: "/api/conf_legal_forms/0114", name: "Kommanditgesellschaft für kollektive Kapitalanlagen"},
    { id: "/api/conf_legal_forms/0115", name: "Investmentgesellschaft mit variablem Kapital (SICAV)"},
    { id: "/api/conf_legal_forms/0116", name: "Investmentgesellschaft mit festem Kapital (SICAF)"},
    { id: "/api/conf_legal_forms/0117", name: "Institut des öffentlichen Rechts"},
    { id: "/api/conf_legal_forms/0118", name: "Nichtkaufmännische Prokuren"},
    { id: "/api/conf_legal_forms/0119", name: "Haupt von Gemeinderschaften"},
    { id: "/api/conf_legal_forms/0151", name: "Schweizerische Zweigniederlassung im Handelsregister eingetragen"},
    { id: "/api/conf_legal_forms/0220", name: "Verwaltung des Bundes"},
    { id: "/api/conf_legal_forms/0221", name: "Verwaltung des Kantons"},
    { id: "/api/conf_legal_forms/0222", name: "Verwaltung des Bezirks"},
    { id: "/api/conf_legal_forms/0223", name: "Verwaltung der Gemeinde"},
    { id: "/api/conf_legal_forms/0224", name: "öffentlich-rechtliche Körperschaft (Verwaltung)"},
    { id: "/api/conf_legal_forms/0230", name: "Unternehmen des Bundes"},
    { id: "/api/conf_legal_forms/0231", name: "Unternehmen des Kantons"},
    { id: "/api/conf_legal_forms/0232", name: "Unternehmen des Bezirks"},
    { id: "/api/conf_legal_forms/0233", name: "Unternehmen der Gemeinde"},
    { id: "/api/conf_legal_forms/0234", name: "öffentlich-rechtliche Körperschaft (Unternehmen)"},
    { id: "/api/conf_legal_forms/0302", name: "Einfache Gesellschaft"},
    { id: "/api/conf_legal_forms/0312", name: "Ausländische Niederlassung nicht im Handelsregister eingetragen"},
    { id: "/api/conf_legal_forms/0327", name: "Ausländisches öffentliches Unternehmen"},
    { id: "/api/conf_legal_forms/0328", name: "Ausländische öffentliche Verwaltung"},
    { id: "/api/conf_legal_forms/0329", name: "Internationale Organisation"},
    { id: "/api/conf_legal_forms/0355", name: "Übrige Genossenschaften"},
    { id: "/api/conf_legal_forms/0361", name: "Trust"},
    { id: "/api/conf_legal_forms/0362", name: "Fonåds"},
    { id: "/api/conf_legal_forms/0441", name: "Ausländische Unternehmen (Entreprise étrangère, impresa straniera)"},
    { id: "/api/conf_legal_forms/0551", name: "Rechtsform unbestimmt oder unbekannt"},]

export const venueCategories = [
    { id: "restaurant", name: "Restaurant" },
    { id:"bar", name: "Bar" },
    { id: "cafe", name: "Café" },
    { id: "bakery", name: "Bäckerei / Konditorei" },
    { id: "club", name: "Club" },
    { id: "popup", name: "Popup" },
    { id: "hotel", name: "Hotel" },
    { id: "takeaway", name: "Take Away"},
    { id: "other", name: "Anderes"},
]

export const sources = [
    { id: 'chuid', name: 'UID Register CH' },
    { id: 'foratable', name: 'forAtable' },
    { id: 'lunchgate', name: 'Lunchgate Backend' },
    { id: 'admin', name: 'Backoffice' },
    { id: 'skip5', name: 'Skip5' },
    { id: 'other', name: 'Andere' },
]

export const addressCategories = [
    { id: 'billing', name: 'Rechnungsadresse' },
    { id: 'legal', name: 'Domizil' },
    { id: 'venue', name: 'Venue' },
    { id: 'pobox', name: 'Postfachadresse' },
]

export const contactCategories = [
    { id: 'info', name: 'Info' },
    { id: 'reservation', name: 'Reservation' },
    { id: 'office', name: 'Office' },
    { id: 'mobile', name: 'Mobil' },
    { id: 'work', name: 'Geschäft' },
    { id: 'private', name: 'Privat' },
    { id: 'billing', name: 'Rechnungsstellung'},
    { id: 'other', name: 'Andere' }, 
]

export const systemNames = [
    { id: 'hubspot', name: 'Hubspot' },
	{ id: 'lunchgate', name: 'Lunchgate Backend' },
	{ id: 'foratable', name: 'Foratable' },
	{ id: 'skip5', name: 'Skip5' },
	{ id: 'bexio', name: 'Bexio' },
	{ id: 'website', name: 'Website' },
	{ id: 'other', name: 'Andere' },
]

export const systemBaseUrls = [
    { id: 'lunchgate', name: "https://backend.lunchgate.ch/restaurant/traffic/id/"},
    { id: 'foratable', name: "https://foratable.com/superadmin/restaurants/dive/" },
    { id: 'hubspot', name: "https://app.hubspot.com/contacts/2444048/companies/"},
]

export const reportingClasses = [
    { id: '/api/conf_reporting_classes/1', name: 'Aufschaltgebuehr' },
    { id: '/api/conf_reporting_classes/2', name: 'Druckvorlagen' },
    { id: '/api/conf_reporting_classes/3', name: 'Ertrag Insider / Social Media / Blog' },
    { id: '/api/conf_reporting_classes/4', name: 'Ertrag NL' },
    { id: '/api/conf_reporting_classes/5', name: 'ForATable flat' },
    { id: '/api/conf_reporting_classes/6', name: 'ForATable IVR' },
    { id: '/api/conf_reporting_classes/7', name: 'ForATable pax' },
    { id: '/api/conf_reporting_classes/8', name: 'ForATable SMS' },
    { id: '/api/conf_reporting_classes/9', name: 'ForATable+' },
    { id: '/api/conf_reporting_classes/10', name: 'Tischplan' },
    { id: '/api/conf_reporting_classes/11', name: 'Fotos / Panobilder / Google Panoramatouren' },
    { id: '/api/conf_reporting_classes/12', name: 'Kombi Abo' },
    { id: '/api/conf_reporting_classes/13', name: 'Dinner Abo' },
    { id: '/api/conf_reporting_classes/14', name: 'Menu Abo' },
    { id: '/api/conf_reporting_classes/15', name: 'not classified' },
    { id: '/api/conf_reporting_classes/16', name: 'Pano Abo' },
    { id: '/api/conf_reporting_classes/17', name: 'Reserve Buecher' },
    { id: '/api/conf_reporting_classes/18', name: 'Schulungen' },
    { id: '/api/conf_reporting_classes/19', name: 'ForATable Setup' },
    { id: '/api/conf_reporting_classes/20', name: 'Social Media' },
    { id: '/api/conf_reporting_classes/21', name: 'Social Media Abo' },
    { id: '/api/conf_reporting_classes/22', name: 'Sonstige Setup' },
    { id: '/api/conf_reporting_classes/23', name: 'Uebriger Ertrag' },
    { id: '/api/conf_reporting_classes/24', name: 'Website Abo' },
    { id: '/api/conf_reporting_classes/25', name: 'Website Setup' },
    { id: '/api/conf_reporting_classes/26', name: 'Covid19 Mobilnummer Verifikationen' },
    { id: '/api/conf_reporting_classes/27', name: 'Menü Karten' },
    { id: '/api/conf_reporting_classes/28', name: 'Destinationslösung' },
    { id: '/api/conf_reporting_classes/29', name: 'Foratable Pay' },
    { id: '/api/conf_reporting_classes/30', name: 'Foratable POS' },
    { id: '/api/conf_reporting_classes/31', name: 'Foratable SMS' },
    { id: '/api/conf_reporting_classes/32', name: 'Foratable Credit Card' },
]

export const statisticNames = [
    { id: '/api/conf_statistic_name/1', name: 'revenue', divisor:  100},
    { id: '/api/conf_statistic_name/2', name: 'numberOfSubscriptions', divisor:  1},
    { id: '/api/conf_statistic_name/3', name: 'numberOfNewPaidSubscriptions', divisor:  1},
    { id: '/api/conf_statistic_name/4', name: 'numberOfLostPaidSubscriptions', divisor:  1},
    { id: '/api/conf_statistic_name/5', name: 'newRevenue', divisor:  100},
    { id: '/api/conf_statistic_name/6', name: 'lostRevenue', divisor:  100},
    { id: '/api/conf_statistic_name/7', name: 'numberOfNewAboClientsSmoothed3m', divisor:  1},
    { id: '/api/conf_statistic_name/8', name: 'numberOfLostAboClientsSmoothed3m', divisor:  1},
    { id: '/api/conf_statistic_name/9', name: 'numberOfPaidSubscriptions', divisor:  1},
]

export const registeredVias = [
    { id: 'registered letter', name: 'Einschreiben' },
    { id: 'letter', name: 'Brief' },
    { id: 'email', name: 'Email' },
    { id: 'other', name: 'Anderer Kanal - bitte im Kommentar beschreiben' }, 
]

export const ctrProducts = [
    { id: '/api/ctr_products/1', name: 'Menü Marketing' },
    { id: '/api/ctr_products/2', name: 'Reservation System' },
    { id: '/api/ctr_products/3', name: 'Tischplan' },
    { id: '/api/ctr_products/4', name: 'Reservation System + Buch' },
    { id: '/api/ctr_products/5', name: 'Kombipaket' },
    { id: '/api/ctr_products/6', name: 'Kombipaket + Buch' },
    { id: '/api/ctr_products/7', name: 'Basis Profil' },
    { id: '/api/ctr_products/8', name: 'Homepage' },
    { id: '/api/ctr_products/9', name: 'Google My Business Schnittstelle' },
    { id: '/api/ctr_products/10', name: 'Facebook Schnittstelle' },
    { id: '/api/ctr_products/11', name: 'Panoplayer' },
    { id: '/api/ctr_products/19', name: 'Dinner Abo' },
    { id: '/api/ctr_products/20', name: 'forAtable Pax' },
    { id: '/api/ctr_products/21', name: 'Kombipaket mit Reservationsbuch' },
    { id: '/api/ctr_products/22', name: 'Conversion Tracking' },
    { id: '/api/ctr_products/23', name: 'Reservationsbuch' },
    { id: '/api/ctr_products/24', name: 'Hosting und Domain' },
    { id: '/api/ctr_products/25', name: 'Website Support Plus' },
    { id: '/api/ctr_products/29', name: 'Covid Modul' },
    { id: '/api/ctr_products/31', name: 'Mobilnummer Verifikationen für Covid Modul' },
    { id: '/api/ctr_products/37', name: '1. Pano' },
    { id: '/api/ctr_products/38', name: '2. - 5. Pano' },
    { id: '/api/ctr_products/40', name: 'Menü Karten' },
    { id: '/api/ctr_products/41', name: 'Destinationslösung pauschal' },
    { id: '/api/ctr_products/42', name: 'Foratable IVR' },
    { id: '/api/ctr_products/43', name: 'Foratable SMS' },
    { id: '/api/ctr_products/44', name: 'Reserve Gutscheinbuch' },
    { id: '/api/ctr_products/45', name: 'Kombipaket - Menü Marketing + Reservation System' },
    { id: '/api/ctr_products/46', name: 'Kombipaket - Menü Marketing + Reservation System & Buch' },
    { id: '/api/ctr_products/47', name: 'Zweites Outlet - Reservation System & Buch' },
    { id: '/api/ctr_products/48', name: 'Drittes Outlet - Reservation System & Buch' },
    { id: '/api/ctr_products/49', name: 'Viertes Outlet - Reservation System & Buch' },
    { id: '/api/ctr_products/50', name: 'Foratable Basic (inkl 100 SMS p.m. + CHF 0.15 pro zusätzliche SMS)' },
    { id: '/api/ctr_products/51', name: 'Foratable Professional (inkl. 300 SMS p.m. + CHF 0.15 pro zusätzliche SMS)' },
    { id: '/api/ctr_products/52', name: 'Foratable Premium (inkl. 300 SMS p.m. + CHF 0.15 pro zusätzliche SMS)' },
    { id: '/api/ctr_products/53', name: 'Foratable Pay (plus CHF 0.25 pro Verifikation + CHF 0.50 pro Transaktion)' },
    { id: '/api/ctr_products/54', name: 'Foratable Premium (plus CHF 0.15 pro SMS)' },
    { id: '/api/ctr_products/55', name: 'Foratable Professional (plus CHF 0.15 pro SMS)' },
    { id: '/api/ctr_products/56', name: 'Foratable Basic (plus CHF 0.15 pro SMS)' },
    { id: '/api/ctr_products/57', name: 'Foratable POS' },
    { id: '/api/ctr_products/58', name: 'Zweites Outlet - Foratable Pay (plus CHF 0.25 pro Verifikation + CHF 0.50 pro Transaktion)' },
    { id: '/api/ctr_products/59', name: 'Drittes Outlet - Foratable Pay (plus CHF 0.25 pro Verifikation + CHF 0.50 pro Transaktion)' },
    { id: '/api/ctr_products/60', name: 'Foratable Basic (plus EUR 0.15 pro SMS)' },
    { id: '/api/ctr_products/61', name: 'Foratable Professional (plus EUR 0.15 pro SMS)' },
    { id: '/api/ctr_products/62', name: 'Foratable Premium (plus EUR 0.15 pro SMS)' },
    { id: '/api/ctr_products/63', name: 'Zweites Outlet - Foratable Professional (plus CHF 0.15 pro SMS)' },
    { id: '/api/ctr_products/64', name: 'SMS' },
    { id: '/api/ctr_products/65', name: 'Kreditkarten Verifikation' },
    { id: '/api/ctr_products/66', name: 'Kreditkarten Transaktion' },
    { id: '/api/ctr_products/67', name: 'Foratable Basic (plus CHF 0.10 pro SMS)' },
    { id: '/api/ctr_products/68', name: 'Drittes Outlet - Foratable Professional (plus CHF 0.15 pro SMS)' },
    { id: '/api/ctr_products/69', name: 'Foratable Basic (inkl 300 SMS p.m. + CHF 0.15 pro zusätzliche SMS)' },
    { id: '/api/ctr_products/70', name: 'Migrationsgebühr' },
    { id: '/api/ctr_products/71', name: 'Foratable Professional (plus CHF 0.07 pro SMS)' },
    { id: '/api/ctr_products/72', name: 'Zweites Outlet - Foratable Professional (plus CHF 0.07 pro SMS)' },
    { id: '/api/ctr_products/73', name: 'Drittes Outlet - Foratable Professional (plus CHF 0.07 pro SMS)' },
    { id: '/api/ctr_products/74', name: 'Foratable Pay (plus EUR 0.25 pro Verifikation + EUR 0.50 pro Transaktion)' },
    { id: '/api/ctr_products/75', name: 'Foratable Caller ID' },
]

export const ctrCancellationReasonType = [
    { id: 'upselling',                  name: 'Produktwechsel: Upselling' },
    { id: 'neither_down_nor_upselling', name: 'Produktwechsel: Weder Up- noch Downselling' },
    { id: 'downselling',                name: 'Produktwechsel: Downselling' },
    { id: 'liquidation',                name: 'externer Grund: Liquidation' },
    { id: 'business_cessation',         name: 'externer Grund: Betriebsaufgabe' },
    { id: 'sale_or_merger_into_chain',  name: 'externer Grund: Verkauf oder Merger in Gastrogruppe oder -kette' },
    { id: 'change_of_business_model',   name: 'externer Grund: Veränderung des Businessmodels (z.B. kein Mittagsgeschäft mehr)' },
    { id: 'bad_support_or_service',     name: 'interner Grund: schlechter Support/Service' },
    { id: 'software_does_not_meet_requirements',
                                        name: 'interner Grund: Software erfüllt Anforderungen nicht' },
    { id: 'onboarding_failed',          name: 'interner Grund: Onboarding hat nicht funktioniert' },
    { id: 'was_sold_wrong_product',     name: 'interner Grund: falsches Produkt verkauft' },
    { id: 'value_for_price',            name: 'interner Grund: schlechtes Preis-Leistungs-Verhältnis' },
    { id: 'terms_and_conditions',       name: 'interner Grund: AGB (z.B. automatische Verlängerung)' },
    { id: 'unknown',                    name: 'interner Grund: Grund unbekannt' },
    { id: 'lunchgate_admin',            name: 'Lunchgate Administration' },
]

export const ctrCancellationReasonCategory = [
    { id: 'internal',                   name: 'interner Grund' },
    { id: 'external',                   name: 'externer Grund' },
    { id: 'product_change',             name: 'Produktwechsel' },
    { id: 'lunchgate_admin',            name: 'Lunchgate Administration' },
]

export const ctrCurrency = [
    { id: 'CHF', name: 'CHF'},
    { id: 'EUR', name: 'EUR'},
]

export const period = [
    { id:"none", name:"keine"},
    { id:"daily", name:"täglich" },
    { id: "monthly", name: "monatlich" },
    { id:"quarterly", name:"quartalsweise (alle 3 Monate)"},
    { id:"semi_annual", name:"halbjährlich (alle 6 Monate)"},
    { id:"annual", name:"jährlich"},
]
