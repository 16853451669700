import * as React from "react";
import {
    Button,downloadCSV,
    Filter,
    TextInput,
    Show,
    List,
    SelectField,
    SelectInput,
    TabbedShowLayout,
    Tab,
    TextField,
    Datagrid,
    DateField,
    RichTextField,
    TopToolbar,
    ListButton,
    ReferenceManyField,
    FunctionField, useRecordContext
} from 'react-admin';
import { ctrCancellationReasonCategory, ctrCancellationReasonType, ctrProducts } from "./enums";
import {makeStyles} from "@mui/styles";
import CancellationButton from "../components/CancellationButton";
import AddCancellationReasonButton from "../components/AddCancellationReasonButton";
import OpusPagination from "../components/OpusPagination";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";
import processProvider from "../utils/processProvider";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CtrCancellationViewTitle = () => {
    const record = useRecordContext();

    return <span>Kündigung {record ? `#${record.crm_contract_relationship_id} - ${record.product}` : ''}</span>;
};

const CtrCancellationViewShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
    </TopToolbar>
);

const ExportButton = () => {
    const handleClick = () => processProvider.exportCancellations().then((csv) => downloadCSV(csv, 'cancellations'))

    return <Button onClick={handleClick}>Export</Button>
}

const CtrCancellationViewListActions = () => (
    <TopToolbar>
        <ExportButton />
    </TopToolbar>
);

const CtrCancellationViewFilter = (props) => (
    <Filter {...props}>
        <TextInput label="CrmContractRelationship" source="crm_contract_relationship_id" alwaysOn  />
        <TextInput label="Venue" source="venue" alwaysOn />
        <TextInput label="Firma" source="firma" alwaysOn />
        <TextInput label="Produkt" source="product" alwaysOn />
        <SelectInput label="Typ" source="type" choices={ctrCancellationReasonType} alwaysOn />
        <SelectInput label="Kategorie" source="category" choices={ctrCancellationReasonCategory} alwaysOn />
     </Filter>
);


export const CtrCancellationViewList = props => (
    <List
        filters={<CtrCancellationViewFilter />}
        title="Kündigungen "
        pagination={<OpusPagination />}
        actions={<CtrCancellationViewListActions/>}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <DateField source="received_date" headerClassName={useStyles().header} />
            <FunctionField label="Vertragsbeziehung" render={record => CrmContractRelationshipIdField(record.crm_contract_relationship_id)} headerClassName={useStyles().header} sortable={false} />
            <TextField source="product" headerClassName={useStyles().header} />
            <TextField source="venue" headerClassName={useStyles().header} />
            <TextField source="firma" headerClassName={useStyles().header} />
            <TextField source="received_via" label="Kündigung per" headerClassName={useStyles().header} />
            <TextField source="sent_by" label="Absender / Kontakt" headerClassName={useStyles().header} />
            <DateField source="requested_termination_date" headerClassName={useStyles().header} />
            <DateField source="contract_until_date" headerClassName={useStyles().header} />
            <DateField source="end_date" headerClassName={useStyles().header} />
            <RichTextField source="note" headerClassName={useStyles().header} />
            <SelectField source="type" label="Kündigungsgrund - Typ" choices={ctrCancellationReasonType} headerClassName={useStyles().header} />
            <SelectField source="category" label="Kündigungsgrund - Kategorie" choices={ctrCancellationReasonCategory} headerClassName={useStyles().header} />
            <RichTextField source="detail" label="Kündigungsgrund - Details" headerClassName={useStyles().header} />
            <TextField source="prepared_by" headerClassName={useStyles().header} />
            <DateField source="prepared" headerClassName={useStyles().header} />
            <AddCancellationReasonButton/>
        </Datagrid>
    </List>
);

export const CtrCancellationViewShow = () => (
    <Show
        title={<CtrCancellationViewTitle />}
        actions={<CtrCancellationViewShowActions/>}
    >
        <TabbedShowLayout>
            <Tab label="Kündigung">
                <TextField source="crm_contract_relationship_id"/>
                <DateField source="received_date" />
                <TextField source="received_via" label="Kündigung per" />
                <DateField source="requested_termination_date" />
                <TextField source="sent_by" label="Absender / Kontakt"/>
                <RichTextField source="note" headerClassName={useStyles().header} />
                <SelectField source="type" label="Kündigungsgrund - Typ" choices={ctrCancellationReasonType}/>
                <TextField source="detail" label="Kündigungsgrund - Detail" />
                <TextField source="prepared_by" />
                <DateField source="prepared" />        
            </Tab>
            <Tab label="Abo">
                <TextField source="crm_contract_relationship_id"/>
                    <TextField source="ctr_subscription_id"/>
                    <TextField source="product" />
                    <DateField source="start_date" />
                    <DateField source="contract_until_date" />
                    <DateField source="end_date" />
                    <RichTextField source="note" />
            </Tab>
            <Tab label="Vertragsbeziehung">
                <TextField source="crm_contract_relationship_id" />
                <TextField source="venue"/>
                <TextField source="firma"/>
                <ReferenceManyField reference="ctr_subscriptions" label="Abos" target="crm_contract_relationship" source="crm_contract_relationship_id" >
                    <Datagrid bulkActionButtons={false} >
                        <TextField source="id" headerClassName={useStyles().header} />
                        <SelectField source="ctr_product" choices={ctrProducts} headerClassName={useStyles().header} />
                        <DateField source="start_date" headerClassName={useStyles().header} />
                        <DateField source="contract_until_date" headerClassName={useStyles().header} />
                        <DateField source="end_date" headerClassName={useStyles().header} />
                        <FunctionField render={record => <CancellationButton record={record} />}/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
