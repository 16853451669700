import * as React from "react";
import {
    TextField,
    Edit,
    SimpleForm,
    SimpleFormIterator,
    Create,
    EmailField,
    ArrayInput,
    List,
    Filter,
    PasswordInput,BooleanInput,
    TextInput,
    Datagrid,
    BooleanField
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="username" source="username" />
        <TextInput label="id" source="id" />
    </Filter>
);

export const UserCreate = props => (
    <Create mutationMode="pessimistic" {...props}>
        <SimpleForm>
            <PasswordInput source="current_password" label="enter your password"/>
            <TextInput source="username"/>
            <BooleanInput source="active" defaultValue={false}/>
            <PasswordInput source="plain_password" label="user password"/>
            <ArrayInput source="roles">
                <SimpleFormIterator>
                    <TextInput defaultValue="ROLE_USER"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const UserList = props => (
    <List
        filters={<UserFilter />}
        title="Benutzer"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <EmailField source="username" headerClassName={useStyles().header} />
            <BooleanField source="active" headerClassName={useStyles().header} />
            <TextField source="roles" headerClassName={useStyles().header} />
        </Datagrid>
    </List>
);

export const UserEdit = props => (
    <Edit mutationMode="pessimistic" {...props}>
        <SimpleForm>
            <PasswordInput source="current_password" label="enter your password"/>
            <TextInput source="username"/>
            <BooleanInput source="active"/>
            <PasswordInput source="plain_password" label="password"/>
            <ArrayInput source="roles">
                <SimpleFormIterator>
                    <TextInput defaultValue="ROLE_USER"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);