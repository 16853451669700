import { AUTHPOINT } from   "../config/entrypoint";
import decodeJwt from 'jwt-decode';

const removeAuthData = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("roles");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("exp");
};

const setAuthData = (token) => {
    const decoded = decodeJwt(token);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("roles", decoded.roles);
    sessionStorage.setItem("username", decoded.username);
    sessionStorage.setItem("exp", decoded.exp);
};

const validateToken = (token, exp) => {
    if (token === null || exp === null) {
        return false;
    }

    return Math.floor(Date.now() / 1000) < exp - 10;
};

const authProvider =  {
    login: ({ username, password }) => {
        const request = new Request(AUTHPOINT, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                setAuthData(token);
            });
    },
    checkError: (error) => {
        const status = error.response.status;
        if (status === 401 || status === 403) {
            removeAuthData();
            return Promise.reject({ message: false });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        return validateToken(sessionStorage.getItem('token'), sessionStorage.getItem('exp')) ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        removeAuthData()
        return Promise.resolve();
    },
    getPermissions: () => {
        const roles = sessionStorage.getItem('roles');
        return roles ? Promise.resolve(roles) : Promise.reject();
    },
    getIdentity: () => sessionStorage.getItem('username') ?
        Promise.resolve( { id: sessionStorage.getItem('username') }) :
        Promise.reject(),
    getToken: () => { return sessionStorage.getItem('token')},
    getUsername: () => { return sessionStorage.getItem('username')},
};


export default authProvider;