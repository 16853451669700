import * as React from "react";
import {
    Create, SimpleForm, FunctionField,
    TextInput, NumberField, BooleanInput, BooleanField,
    DateInput, NumberInput,
    Datagrid,
    TextField, useRedirect, useNotify,
    DateField,
    required, List, Filter, SelectInput
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import RelationshipReferenceInput from '../components/RelationshipReferenceInputForBexioVoucher';
import CtrProductReferenceInput from '../components/CtrProductReferenceInput';
import {ctrCurrency} from "./enums";
import OpusPagination from "../components/OpusPagination";


const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const BexioCreditVoucherItemFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Vertragsbeziehungsnummer" source="crm_contract_relationship_id" alwaysOn  />
        <TextInput label="Credit Voucher Nr" source="credit_voucher_nr" alwaysOn  />
    </Filter>
);

export const BexioCreditVoucherItemList = props => (
    <List
        title="Bexio Gutschriftpositionen"
        filters={<BexioCreditVoucherItemFilter/>}
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <TextField source="credit_voucher_nr" headerClassName={useStyles().header} />
            <DateField source="voucher_date" headerClassName={useStyles().header} />
            <TextField source="credit_voucher_text" headerClassName={useStyles().header} />
            <TextField source="crm_contract_relationship_id" headerClassName={useStyles().header}/>
            <NumberField source="ctr_product_id" headerClassName={useStyles().header}/>
            <TextField source="currency" headerClassName={useStyles().header}/>
            <FunctionField label="net_amount" render={record => (record.net_amount/100).toFixed(2)} headerClassName={useStyles().header}/>
            <FunctionField label="gross_amount" render={record => (record.gross_amount/100).toFixed(2)} headerClassName={useStyles().header}/>
            <TextField source="invoice_id" headerClassName={useStyles().header}/>
            <DateField source="start_month" headerClassName={useStyles().header}/>
            <DateField source="end_month" headerClassName={useStyles().header}/>
            <BooleanField source="is_offset_by_corona_gutschrift_rueckstellung" headerClassName={useStyles().header}/>
            <BooleanField source="is_bad_debt_charge" headerClassName={useStyles().header}/>
        </Datagrid>
    </List>
);


export const BexioCreditVoucherItemCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = () => {
        redirect('list', props.basepath);
    }
    const transform = data => ({
        ...data,
        crm_contract_relationship_id: parseInt(data.crm_contract_relationship_id.slice(data.crm_contract_relationship_id.lastIndexOf('/') + 1)),
        ctr_product_id: parseInt(data.ctr_product_id.slice(data.ctr_product_id.lastIndexOf('/') + 1)),
    });
    const onSuccess = () => {
        notify('Bexio Gutschriftposition erfolgreich erfasst');
        redirect('list', props.basepath);
    }
    const defaultValues = {
        is_offset_by_corona_gutschrift_rueckstellung: false,
            is_bad_debt_charge: false,
            net_amount: 0,
            gross_amount: 0,
            currency: 'CHF',
    }

    return (
        <Create
            transform={transform}
            mutationOptions={{onSuccess: onSuccess, onError: onFailure}}
            {...props}
            title="Bexio Gutschriftposition erfassen"
        >
            <SimpleForm defaultValues={defaultValues}>
                    <TextInput validate={[required()]} source="credit_voucher_nr" />
                    <DateInput validate={[required()]} source="voucher_date" />
                    <TextInput validate={[required()]} source="credit_voucher_text" />
                    <RelationshipReferenceInput source="crm_contract_relationship_id" reference="crm_contract_relationship_views" />
                    <CtrProductReferenceInput validate={[required()]} source="ctr_product_id" reference="ctr_products" />
                    <SelectInput choices={ctrCurrency} validate={[required()]} source="currency" />
                    <NumberInput validate={[required()]} label="Nettobetrag" source="net_amount" format={v => v / 100} parse={v => Math.round(parseFloat(v) * 100)} />
                    <NumberInput validate={[required()]} label="Bruttobetrag" source="gross_amount" format={v => v / 100} parse={v => Math.round(parseFloat(v) * 100)} />
                    <NumberInput validate={[required()]} label="Rechnungsnummer" source="invoice_id" />
                    <DateInput validate={[required()]} source="start_month" />
                    <DateInput validate={[required()]} source="end_month" />
                    <BooleanInput validate={[required()]} source="is_offset_by_corona_gutschrift_rueckstellung" />
                    <BooleanInput validate={[required()]} source="is_bad_debt_charge" />
            </SimpleForm>
        </Create>
    );
}
