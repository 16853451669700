import * as React from "react";
import {
    FunctionField,
    TextInput,
    NumberField,
    NumberInput,
    Datagrid,
    TextField,
    List, Show, TabbedShowLayout, Tab, ReferenceManyField,
    Filter, SelectInput,
    TopToolbar,
    SingleFieldList, useRecordContext, downloadCSV, Button
} from 'react-admin';
import {makeStyles} from "@mui/styles";

import CalculateAccrualsButton from "../components/CalculateAccrualsButton";
import {ctrCurrency} from "./enums";
import OpusPagination from "../components/OpusPagination";
import processProvider from "../utils/processProvider";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    },
    chip_main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: {
        margin: 5,
        backgroundColor: "aliceblue"
    }
});
const CalcInvoiceItemViewFilter = (props) => (
    <Filter {...props}>
        <NumberInput label="Vertragsbeziehung" source="crm_contract_relationship" alwaysOn />
        <TextInput label="Venue" source="venue" alwaysOn />
        <TextInput label="Firma" source="firma" alwaysOn />
        <TextInput label="Produkt" source="product"  alwaysOn />
        <TextInput label="Rechnungsmonat" source="invoice_month" alwaysOn  />
        <TextInput label="Konto" source="account" alwaysOn />
        <SelectInput label="Währung" source="currency" choices={ctrCurrency} alwaysOn />
        <NumberInput label="Rechnungsnummer" source="invoice_id" alwaysOn />
    </Filter>
);
const CalcInvoiceItemViewListActions = () => (
    <TopToolbar>
        <CalculateAccrualsButton/>
        <ExportButton/>
        <ExportAccrualsButton/>
    </TopToolbar>
);

const ExportButton = () => {
    const handleClick = () => processProvider.exportCalcInvoiceItemViews().then((csv) => downloadCSV(csv, 'invoice_items'))

    return <Button onClick={handleClick}>Export</Button>
}

const ExportAccrualsButton = () => {
    const handleClick = () => processProvider.exportCalcAccruedRevenues().then((csv) => downloadCSV(csv, 'accrued_revenues'))

    return <Button onClick={handleClick}>Export Abgrenzungen</Button>
}


const CalcInvoiceItemViewTitle = () => {
    const record = useRecordContext();
    return <span>Rechnungspositionen für die Berechnung der Abgrenzungen {record ? `#${record.crm_contract_relationship} - ${record.venue} - ${record.firma} - ${record.invoice_month} - ${record.product}` : ''}</span>;
};

export const CalcInvoiceItemViewList = props => (
    <List
        title={<CalcInvoiceItemViewTitle/>}
        filters={<CalcInvoiceItemViewFilter/>}
        actions={<CalcInvoiceItemViewListActions/>}
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show"  >
            <TextField source="crm_contract_relationship" label="Vertragsbeziehung" headerClassName={useStyles().header}/>
            <TextField source="venue" headerClassName={useStyles().header}/>
            <TextField source="firma" headerClassName={useStyles().header}/>
            <TextField source="product" headerClassName={useStyles().header}/>
            <TextField source="invoice_month" label="Rechnungsmonat" headerClassName={useStyles().header}/>
            <TextField source="start_month" headerClassName={useStyles().header}/>
            <TextField source="end_month" headerClassName={useStyles().header}/>
            <NumberField source="month_count" headerClassName={useStyles().header}/>
            <TextField source="account" label="Konto" headerClassName={useStyles().header}/>
            <TextField source="currency" label="Währung" headerClassName={useStyles().header}/>
            <FunctionField label="amount" render={record => (record.amount/100).toFixed(2)} headerClassName={useStyles().header}/>
            <TextField source="invoice_id" label="Rechnungsnummer" headerClassName={useStyles().header}/>
            <TextField source="system_name" label="System" headerClassName={useStyles().header}/>
        </Datagrid>
    </List>
);

export const CalcInvoiceItemViewShow = props => (
    <Show title={<CalcInvoiceItemViewTitle/>} filters={<CalcInvoiceItemViewFilter/>}
          actions={<CalcInvoiceItemViewListActions/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Rechnung">
                <TextField source="crm_contract_relationship" label="Vertragsbeziehung" headerClassName={useStyles().header}/>
                <TextField source="venue" headerClassName={useStyles().header}/>
                <TextField source="firma" headerClassName={useStyles().header}/>
                <TextField source="product" headerClassName={useStyles().header}/>
                <TextField source="invoice_month" label="Rechnungsmonat" headerClassName={useStyles().header}/>
                <TextField source="start_month" headerClassName={useStyles().header}/>
                <TextField source="end_month" headerClassName={useStyles().header}/>
                <NumberField source="month_count" headerClassName={useStyles().header}/>
                <TextField source="account" label="Konto" headerClassName={useStyles().header}/>
                <TextField source="account" label="Konto" headerClassName={useStyles().header}/>
                <FunctionField label="amount" render={record => (record.amount / 100).toFixed(2)} headerClassName={useStyles().header}/>
                <TextField source="invoice_id" label="Rechnungsnummer" headerClassName={useStyles().header}/>
                <TextField source="system_name" label="System" headerClassName={useStyles().header}/>
                <ReferenceManyField source="originId"
                                    reference="calc_accrued_revenues"
                                    target="calc_invoice_item.id"
                                    sort={{field: 'revenue_month', order: 'ASC'}}
                                    label="Abgegrenzte Erträge gemäss Buchhaltung"
                                    headerClassName={useStyles().header}>
                    <SingleFieldList>
                        <FunctionField label="amount"
                                       render={record => record.revenue_month.toLocaleString().slice(0, 7) + ": " +
                                           (record.amount / 100).toFixed(2)} headerClassName={useStyles().header}
                                       className={useStyles().chip}/>
                    </SingleFieldList>
                </ReferenceManyField>
                <ReferenceManyField source="originId"
                                    reference="calc_invoice_item_accruals"
                                    target="calc_invoice_item.id"
                                    sort={{field: 'revenue_month', order: 'ASC'}}
                                    label="Abgegrenzte Erträge gemäss DWH"
                                    headerClassName={useStyles().header}>
                    <SingleFieldList>
                        <FunctionField label="amount"
                                       render={record => record.revenue_month.toLocaleString().slice(0, 7) + ": " +
                                           (record.amount / 100).toFixed(2)} headerClassName={useStyles().header}
                                       className={useStyles().chip}/>
                    </SingleFieldList>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);



