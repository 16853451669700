import React from 'react';
import { useWatch } from 'react-hook-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import CtrProductQuickPreviewButton from './CtrProductQuickPreviewButton';

const CtrProductReferenceInput = props => {
    const ctrProduct = useWatch({name: 'ctr_product'});

    return (
        <>
            <ReferenceInput
                {...props}
                label="Produkt"
                fullwidth
            >
                <AutocompleteInput 
                    shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                    filterToQuery={searchText => ({ name: searchText, 'valid_until[after]': new Date().toJSON().slice(0,10) })}
                    optionText="name"
                    optionValue="id"
                    source="name"
                    fullWidth
                />
            </ReferenceInput>
            {!!ctrProduct && <CtrProductQuickPreviewButton id={ctrProduct} />}
        </>
    );
};

export default CtrProductReferenceInput;
