import PlusOneIcon from '@mui/icons-material/PlusOne';
import {Button, Link, useRecordContext} from "react-admin";

const AddEmailButton = ({ crmEntityId }) => {
    const record = useRecordContext();

    return <Link
            to={{
                pathname: "/crm_emails/create",
                search: `?crm_entity=${crmEntityId}&record_id=${ record.id }`,
            }}
        >
            <Button
                label="Add an email address"
                onClick={(e) => e.stopPropagation()}
            >
                <PlusOneIcon />
            </Button>
        </Link>
}

export default AddEmailButton;
