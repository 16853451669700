import * as React from "react";
import {
    Filter,
    List,
    Datagrid,
    TextField,
    NumberField, FunctionField,
    Show,
    TabbedShowLayout,SimpleShowLayout,
    Tab,
    TextInput,
    BooleanField,
    DateField,
    ReferenceManyField,
    Create,
    SimpleForm, required, useRecordContext, TopToolbar, ReferenceField, Pagination
} from 'react-admin';
import { makeStyles } from "@mui/styles";
import { DynamicUrlField } from "../components/UrlField"
import VenueReferenceInput from "../components/VenueReferenceInput";
import OrganisationReferenceInput from "../components/OrganisationReferenceInput";
import AddAddressButton from "../components/AddAddressButton";
import AddEmailButton from "../components/AddEmailButton";
import AddPhoneNumberButton from "../components/AddPhoneNumbersButton";
import AddSystemButton from "../components/AddSystemButton";
import SoftDeleteButton from "../components/SoftDeleteButton";
import CancellationButton from "../components/CancellationButton";
import GroupReferenceInput from "../components/GroupReferenceInput";
import AddCrmContractRelationshipButton from "../components/AddCrmContractRelationshipButton";
import FileDownload from "../components/FileDownload";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CrmContractRelationshipFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" alwaysOn  />
        <TextInput label="Venue" source="venue" alwaysOn />
        <TextInput label="Firma" source="firma" alwaysOn />
        <TextInput label="Gastrogruppe" source="gastrogruppe" alwaysOn />
        <TextInput label="Zip" source="zip" alwaysOn />
        <TextInput label="City" source="city" alwaysOn />
        <TextInput label="Lunchgate Id" source="lunchgate"  />
        <TextInput label="Foratable Id" source="foratable"  />
        <TextInput label="Hubspot Id" source="hubspot"  />
    </Filter>
);

const CrmContractRelationshipListActions = () => (
    <TopToolbar>
        <AddCrmContractRelationshipButton/>
    </TopToolbar>
);

const CrmContractRelationshipTitle = () => {
    const record = useRecordContext();

    return <span>Vertragsbeziehung {record ? `#${record.originId}` : ''}</span>;
};

export const CrmContractRelationshipList = props => (
    <List
        filters={<CrmContractRelationshipFilter />}
        title="Vertragsbeziehungen"
        actions={<CrmContractRelationshipListActions/>}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50]} />}
        {...props}
    >
        <Datagrid optimized rowClick="show" bulkActionButtons={false} >
            <TextField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="venue" headerClassName={useStyles().header} />
            <TextField source="firma" headerClassName={useStyles().header} />
            <TextField source="gastrogruppe" headerClassName={useStyles().header} />
            <TextField source="zip" headerClassName={useStyles().header}  />
            <TextField source="city" headerClassName={useStyles().header}  />
            <TextField source="country" headerClassName={useStyles().header}  />
            <FunctionField label="Lunchgate" render={record => DynamicUrlField("lunchgate", record.lunchgate)} headerClassName={useStyles().header} />
            <FunctionField label="forAtable" render={record => DynamicUrlField("foratable", record.foratable)} headerClassName={useStyles().header} />
            <FunctionField label="Hubspot" render={record => DynamicUrlField("hubspot", record.hubspot)} headerClassName={useStyles().header} />
            <FunctionField label="verrechnet" render={record => record.invoice_statistic && (record.invoice_statistic.invoice_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <FunctionField label="bezahlt" render={record => record.invoice_statistic && (record.invoice_statistic.paid_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <FunctionField label="offen" render={record => record.invoice_statistic && (record.invoice_statistic.open_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <DateField source="created" headerClassName={useStyles().header} />
            <DateField source="modified" headerClassName={useStyles().header} />
        </Datagrid>
    </List>
);

export const CrmContractRelationshipCreate = props => {
    const transform = data => {
        if (!data.crm_group) {
            delete data.crm_group
        }
        return data
    }

    return (
        <Create
            transform={transform}
            {...props}
        >
            <SimpleForm>
                <VenueReferenceInput source="crm_venue" reference="crm_venues" validate={[required()]} />
                <OrganisationReferenceInput source="crm_organisation" reference="crm_organisations" validate={[required()]} />
                <GroupReferenceInput source="crm_group" reference="crm_groups" />
            </SimpleForm>
        </Create>
    );
}

export const CrmContractRelationshipShow = (props) => (
    <Show title={<CrmContractRelationshipTitle />} {...props} >
        <TabbedShowLayout>
            <Tab label="Vertragsbeziehung">
                <TextField label="id" source="originId" headerClassName={useStyles().header} />
                <TextField source="venue" headerClassName={useStyles().header}  />
                <TextField source="firma" headerClassName={useStyles().header}  />
                <TextField source="gastrogruppe" headerClassName={useStyles().header}  />
                <FunctionField label="Lunchgate" render={record => DynamicUrlField("lunchgate", record.lunchgate)} headerClassName={useStyles().header} />
                <FunctionField label="forAtable" render={record => DynamicUrlField("foratable", record.foratable)} headerClassName={useStyles().header} />
                <FunctionField label="Hubspot" render={record => DynamicUrlField("hubspot", record.hubspot)} headerClassName={useStyles().header} />
                <FunctionField label="verrechnet" render={record => (record.invoice_statistic ? record.invoice_statistic.invoice_amount/100 : 0).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
                <FunctionField label="bezahlt" render={record => (record.invoice_statistic ? record.invoice_statistic.paid_amount/100 : 0).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
                <FunctionField label="offen" render={record => (record.invoice_statistic ? record.invoice_statistic.open_amount/100 : 0).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
                <ReferenceField reference="crm_organisations" source="crm_organisation" label="Rechnungsversand"
                                link={false}>
                    <TextField source="invoice_preference"/>
                </ReferenceField>
                <DateField source="created" headerClassName={useStyles().header} />
                <DateField source="modified" headerClassName={useStyles().header} />
            </Tab>
            <Tab label="Venue">
                <SimpleShowLayout>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="Venue" link={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="Kategorie" link={false}>
                        <TextField source="category"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="Aktuelle Venue Adresse"
                                    link={false}>
                        <ReferenceField reference="crm_addresses" source="current_venue_address"
                                        label="Aktuelle Rechnungsadresse" link={false}>
                            <TextField source="address_line1"/>{', '}<TextField source="address_line2"/>{', '}
                            <TextField source="zip"/>{', '}
                            <TextField source="city"/>{', '}
                            <TextField source="country"/>
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="Adressen" link={false}>
                        <ReferenceManyField
                            reference="crm_addresses"
                            target="crm_entity"
                            source="crm_entity"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="category" headerClassName={useStyles().header}/>
                                <TextField source="source" headerClassName={useStyles().header}/>
                                <TextField source="address_line1" headerClassName={useStyles().header}/>
                                <TextField source="address_line2" headerClassName={useStyles().header}/>
                                <TextField source="zip" headerClassName={useStyles().header}/>
                                <TextField source="city" headerClassName={useStyles().header}/>
                                <TextField source="is_deliverable" label="zustellbar" headerClassName={useStyles().header}/>
                                <TextField source="date_of_last_check" label="letzte Prüfung"
                                       headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label={false} link={false}>
                        <FunctionField render={record => <AddAddressButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField
                        reference="crm_venues" source="crm_venue" label="Telefonnummern" link={false}>
                        <ReferenceManyField
                            reference="crm_phone_numbers"
                            target="crm_entity"
                            source="crm_entity"
                            label="phone"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="category" headerClassName={useStyles().header}/>
                                <TextField source="source" headerClassName={useStyles().header}/>
                                <TextField source="phone_number" label="Telefonnummer" headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label={false} link={false}>
                        <FunctionField render={record => <AddPhoneNumberButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="Email" link={false}>
                        <ReferenceManyField
                            reference="crm_emails"
                            target="crm_entity"
                            source="crm_entity"
                            label="email"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="category" headerClassName={useStyles().header}/>
                                <TextField source="source" headerClassName={useStyles().header}/>
                                <TextField source="email" label="Email" headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label={false} link={false}>
                        <FunctionField render={record => <AddEmailButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="Externe Systeme" link={false}>
                        <ReferenceManyField
                            reference="crm_systems"
                            target="crm_entity"
                            source="crm_entity"
                            label="andere Systeme"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="system_name" headerClassName={useStyles().header}/>
                                <FunctionField label="SystemId" render={record => DynamicUrlField(record.system_name, record.system_id)} headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label={false} link={false}>
                        <FunctionField render={record => <AddSystemButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="Created" link={false}>
                        <DateField source="created"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_venues" source="crm_venue" label="modified" link={false}>
                        <DateField source="modified"/>
                    </ReferenceField>
                </SimpleShowLayout>
            </Tab>
            <Tab label="Organisation">
                <SimpleShowLayout>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Organisation"
                                    link={false}>
                        <TextField source="legal_name"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Aktuelle Rechnungsadresse"
                                    link={false}>
                        <ReferenceField reference="crm_addresses" source="current_billing_address"
                                        label="Aktuelle Rechnungsadresse" link={false}>
                            <TextField source="address_line1"/>{', '}<TextField source="address_line2"/>{', '}
                            <TextField source="zip"/>{', '}
                            <TextField source="city"/>{', '}
                            <TextField source="country"/>
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation"
                                    label="Zustellbarkeit geprüft" link={false}>
                        <ReferenceField reference="crm_addresses" source="current_billing_address" link={false}>
                            <TextField source="is_deliverable"/>{', '}
                            <DateField source="date_of_last_check"/>
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Aktuelle Rechnungsemail"
                                    link={false}>
                        <ReferenceField reference="crm_emails" source="current_billing_email" link={false}>
                            <TextField source="email" />
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation"
                                    label="Rechnungsversand" link={false}>
                       <TextField source="invoice_preference" />
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Adressen"
                                    link={false}>
                        <ReferenceManyField
                            reference="crm_addresses"
                            target="crm_entity"
                            source="crm_entity"
                            sort={{field: 'id', order: 'asc'}}
                            label="Adressen"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="category" headerClassName={useStyles().header}/>
                                <TextField source="address_line1" headerClassName={useStyles().header}/>
                                <TextField source="address_line2" headerClassName={useStyles().header}/>
                                <TextField source="zip" headerClassName={useStyles().header}/>
                                <TextField source="city" headerClassName={useStyles().header}/>
                                <TextField source="canton" headerClassName={useStyles().header}/>
                                <TextField source="country" headerClassName={useStyles().header}/>
                                <TextField source="source" headerClassName={useStyles().header}/>
                                <TextField source="is_deliverable" label="zustellbar"
                                           headerClassName={useStyles().header}/>
                                <TextField source="date_of_last_check" label="letzte Prüfung"
                                           headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label={false} link={false}>
                        <FunctionField render={record => <AddAddressButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Telefonnummern"
                                    link={false}>
                        <ReferenceManyField
                            reference="crm_phone_numbers"
                            target="crm_entity"
                            source="crm_entity"
                            label="phone"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="category" headerClassName={useStyles().header}/>
                                <TextField source="source" headerClassName={useStyles().header}/>
                                <TextField source="phone_number" label="Telefonnummer"
                                           headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label={false} link={false}>
                        <FunctionField render={record => <AddPhoneNumberButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation"
                                    label="Zustellbarkeit geprüft" link={false}>
                        <ReferenceManyField
                            reference="crm_emails"
                            target="crm_entity"
                            source="crm_entity"
                            label="email"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="category" headerClassName={useStyles().header}/>
                                <TextField source="source" headerClassName={useStyles().header}/>
                                <TextField source="email" label="Email" headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label={false} link={false}>
                        <FunctionField render={record => <AddEmailButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Externe Systeme"
                                    link={false}>
                        <ReferenceManyField
                            reference="crm_systems"
                            target="crm_entity"
                            source="crm_entity"
                            label="andere Systeme"
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="system_name" headerClassName={useStyles().header}/>
                                <FunctionField label="systemId"
                                               render={record => DynamicUrlField(record.system_name, record.system_id)}
                                               headerClassName={useStyles().header}/>
                                <DateField source="valid_from" headerClassName={useStyles().header}/>
                                <DateField source="valid_until" headerClassName={useStyles().header}/>
                                <FunctionField render={record => <SoftDeleteButton record={record}/>}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label={false} link={false}>
                        <FunctionField render={record => <AddSystemButton crmEntityId={record.crm_entity}/>}/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Rechtsform"
                                    link={false}>
                        <TextField source="legal_form" label="Rechtsform"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="UID" link={false}>
                        <TextField source="uid"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Land" link={false}>
                        <TextField source="country" label="Land"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Created"
                                    link={false}>
                        <DateField source="created"/>
                    </ReferenceField>
                    <ReferenceField reference="crm_organisations" source="crm_organisation" label="Modified"
                                    link={false}>
                        <DateField source="modified"/>
                    </ReferenceField>
                </SimpleShowLayout>
             </Tab>
            <Tab label="Rechnungen">
                <FunctionField
                    label="Rechnungstotal"
                    render={record => (record.invoice_statistic.invoice_amount / 100).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    className={useStyles().total}
                />
                <FunctionField
                    label="Offene Rechnungsbeträge"
                    render={record => (record.invoice_statistic.open_amount / 100).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    className={useStyles().total}
                />
                <ReferenceManyField
                    reference="ctr_invoices"
                    target="crm_contract_relationship.id"
                    source="originId"
                    label="Rechnungen"
                    sort={{ field: 'invoice_date', order: 'DESC'}}
                >
                    <Datagrid rowClick="show" bulkActionButtons={false} sort={{ field: 'invoice_date', order: 'DESC'}}>
                        <TextField label="Nr" source="invoice_nr" headerClassName={useStyles().header} />
                        <TextField label="Source" source="system_name" headerClassName={useStyles().header} />
                        <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship"  label="Vertragsbeziehung" headerClassName={useStyles().header} link={false} >
                            <NumberField source="originId"  headerClassName={useStyles().header} sortable={false} />{' - '}
                            <ReferenceField reference="crm_venues" source="crm_venue" link={false} ><TextField source="name"/></ReferenceField>{' - '}
                            <ReferenceField reference="crm_organisations" source="crm_organisation" link={false} ><TextField source="legal_name"/></ReferenceField>
                        </ReferenceField>
                        <TextField label="Rechnungsadresse" source="address" headerClassName={useStyles().header} sortable={false} />
                        <DateField label="Rg Datum" source="invoice_date" headerClassName={useStyles().header} sortable={false} />
                        <DateField label="Fälligkeit" source="pay_by_date" headerClassName={useStyles().header} sortable={false} />
                        <FunctionField label="Netto" render={record => (record.net_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                        <FunctionField label="Brutto" render={record => (record.total_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                        <TextField source="status" headerClassName={useStyles().header} sortable={false} />
                        <FunctionField label="Bezahlt" render={record => (record.paid_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                        <FunctionField label="Offen" render={record => (record.open_amount/100).toFixed(2)} headerClassName={useStyles().header} />
                        <FunctionField
                            render={record => <FileDownload mediaObject={record.invoice_pdf_url} fileName={record.invoice_nr + '.pdf'} label="pdf" />}
                            headerClassName={useStyles().header}
                            label="PDF"
                        />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Verträge">
                <ReferenceManyField reference="ctr_contracts" target="crm_contract_relationship.id" source="originId" sort={{field: 'start_date', order:'desc'}}>
                    <Datagrid rowClick="show" bulkActionButtons={false} >
                        <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
                        <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Venue" headerClassName={useStyles().header} link={false} >
                            <ReferenceField reference="crm_venues" source="crm_venue"  link={false}><TextField source="name"/></ReferenceField>
                        </ReferenceField>
                        <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Organisation" headerClassName={useStyles().header} link={false} >
                            <ReferenceField reference="crm_organisations" source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
                        </ReferenceField>
                        <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                            <FunctionField render={record => record.crm_group && <ReferenceField reference="crm_groups" source="crm_group" link={false}><TextField source="name"/></ReferenceField>} />
                        </ReferenceField>
                        <TextField source="currency" headerClassName={useStyles().header} sortable={false} />
                        <FunctionField label="Betrag" render={record => (record.contract_total/100).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header}/>
                        <DateField source="start_date" headerClassName={useStyles().header} />
                        <BooleanField source="automatic_renewal" headerClassName={useStyles().header} />
                        <TextField source="automatic_renewal_period" headerClassName={useStyles().header} />
                        <TextField source="billing_period" headerClassName={useStyles().header} />
                        <DateField source="created" headerClassName={useStyles().header} />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label="Abos">
                <ReferenceManyField reference="ctr_subscription_views" label="Abos" target="crm_contract_relationship_id" source="originId" >
                    <Datagrid rowClick="show" bulkActionButtons={false} >
                        <TextField label="id" source="originId" headerClassName={useStyles().header} />
                        <TextField source="product" headerClassName={useStyles().header} />
                        <BooleanField source="automatic_renewal" headerClassName={useStyles().header} />
                        <TextField source="automatic_renewal_period" headerClassName={useStyles().header} />
                        <TextField source="cancellation" headerClassName={useStyles().header} />
                        <TextField source="notice_period_in_days" headerClassName={useStyles().header} />
                        <TextField source="billing_period" headerClassName={useStyles().header} />
                        <TextField source="terms_and_conditions" headerClassName={useStyles().header} />
                        <DateField source="start_date" headerClassName={useStyles().header} />
                        <DateField source="billed_until_date" headerClassName={useStyles().header} />
                        <DateField source="contract_until_date" headerClassName={useStyles().header} />
                        <DateField source="end_date" headerClassName={useStyles().header} />
                        <FunctionField render={record => <CancellationButton record={record} />}/>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);