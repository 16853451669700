import * as React from "react";
import {
    Create,
    Edit, Show, SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    SelectInput,
    ReferenceManyField, NumberField,
    Datagrid,
    TextField, FunctionField,
    DateField, FormTab, TabbedForm,
    List, Filter, TopToolbar, ListButton, ShowButton, EditButton, useRecordContext, SelectField, ReferenceField
} from 'react-admin';
import { countries, venueCategories } from "./enums";
import { DynamicUrlField } from "../components/UrlField";
import AddAddressButton from "../components/AddAddressButton";
import AddEmailButton from "../components/AddEmailButton";
import AddPhoneNumberButton from "../components/AddPhoneNumbersButton";
import AddSystemButton from "../components/AddSystemButton";
import {makeStyles} from "@mui/styles";
import SoftDeleteButton from "../components/SoftDeleteButton";
import OpusPagination from "../components/OpusPagination";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CrmVenueTitle = () => {
    const record = useRecordContext();

    return <span>Venue {record ? `#${record.originId} - ${record.name}` : ''}</span>;
};

const CrmVenueFilter = (props) => (
    <Filter {...props}>
        <TextInput label="name" source="name" alwaysOn />
        <SelectInput source="country" label="Land" choices={countries} alwaysOn  />
    </Filter>
)

const CrmVenueShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <EditButton />
    </TopToolbar>
);

const CrmVenueEditActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <ShowButton />
    </TopToolbar>
);

export const CrmVenueList = props => (
    <List
        filters={<CrmVenueFilter />}
        title="Venue"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="name" headerClassName={useStyles().header} />
            <TextField source="category" headerClassName={useStyles().header} />
            <SelectField source="country" choices={countries} label="Land" headerClassName={useStyles().header} />
            <ReferenceField
                reference="crm_addresses"
                source="current_venue_address"
                label="Addresszeile 1"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="address_line1"  />
            </ReferenceField>
            <ReferenceField
                reference="crm_addresses"
                source="current_venue_address"
                label="Addresszeile 2"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="address_line2"  />
            </ReferenceField>
            <ReferenceField
                reference="crm_addresses"
                source="current_venue_address"
                label="PLZ"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="zip"  />
            </ReferenceField>
            <ReferenceField
                reference="crm_addresses"
                source="current_venue_address"
                label="Ort"
                link={false}
                sortable={false}
                headerClassName={useStyles().header}
            >
                <TextField source="city"  />
            </ReferenceField>
            <EditButton/>
        </Datagrid>
    </List>
);

// TODO: Fix this
export const CrmVenueCreate = (props) =>(
        <Create mutationMode="pessimistic" redirect={"show"} {...props} >
            <TabbedForm>
                <FormTab label="Venue">
                    <TextInput source="name" validate={[required()]} />
                    <SelectInput source="category" label="Kategorie" choices={venueCategories} validate={[required()]} />
                    <SelectInput source="country" label="Land" choices={countries} defaultValue="CHE" />
                </FormTab>
            </TabbedForm>
        </Create>
    );

// TODO: fix deletes for emails, phones, addresses and systems
export const CrmVenueShow = (props) => (
    <Show {...props} actions={<CrmVenueShowActions />} title={<CrmVenueTitle />}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="category" />
            <SelectField source="country" choices={countries} label="Land" />
            <ReferenceField reference="crm_addresses" source="current_venue_address" label="Aktuelle Venue Adresse" link={false} >
                <TextField source="address_line1" />{', '}
                <TextField source="address_line2" />{', '}
                <TextField source="zip" />{', '}
                <TextField source="city" />{', '}
                <TextField source="country" />
            </ReferenceField>
            <ReferenceManyField
                reference="crm_addresses"
                target="crm_entity"
                source="crm_entity"
                label="adressen"
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="category" />
                    <TextField source="source" />
                    <TextField source="address_line1" />
                    <TextField source="address_line2" />
                    <TextField source="address_line2" />
                    <TextField source="zip" />
                    <TextField source="city" />
                    <TextField source="country" />
                    <TextField source="is_deliverable" label="zustellbar" />
                    <TextField source="date_of_last_check" label="letzte Prüfung" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddAddressButton crmEntityId={record.crm_entity}/>} />
            <ReferenceManyField
                reference="crm_phone_numbers"
                target="crm_entity"
                source="crm_entity"
                label="phone"
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="category" />
                    <TextField source="source" />
                    <TextField source="phone_number" label="Telefonnummer" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddPhoneNumberButton crmEntityId={record.crm_entity} />}/>
            <ReferenceManyField
                reference="crm_emails"
                target="crm_entity"
                source="crm_entity"
                label="email"
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="category" />
                    <TextField source="source" />
                    <TextField source="email" label="Email" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddEmailButton crmEntityId={record.crm_entity} />}/>
            <ReferenceManyField
                reference="crm_systems"
                target="crm_entity"
                source="crm_entity"
                label="andere Systeme"
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="system_name" />
                    <FunctionField label="SystemId" render={record => DynamicUrlField(record.system_name, record.system_id)}/>
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <FunctionField render={record => <SoftDeleteButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
            <FunctionField render={record => <AddSystemButton crmEntityId={record.crm_entity} />}/>
            <ReferenceManyField
                reference="crm_contract_relationship_views"
                target="crm_venue"
                source="id"
                label={"Vertragsbeziehung"}
                sort={{field: 'id', order:'asc'}}
            >
                <Datagrid bulkActionButtons={false} >
                    <FunctionField label="Vertragsbeziehung" render={record => CrmContractRelationshipIdField(record.originId)} sortable={false} />
                    <TextField source="firma" />
                </Datagrid>
            </ReferenceManyField>
            <DateField source="created" />
            <DateField source="modified" />
        </SimpleShowLayout>
    </Show>
);

// TODO: Fix this
export const CrmVenueEdit = (props) => {
    const transform = data => ({
        name: data.name,
        category: data.category,
        country: data.country,
    });

    return (
        <Edit mutationMode="pessimistic" transform={transform} {...props} actions={<CrmVenueEditActions />} undoable="false" title={<CrmVenueTitle />}>
            <SimpleForm>
                <TextInput source="name" />
                <SelectInput source="category" label="Kategorie" choices={venueCategories} />
                <SelectInput source="country" label="Land" choices={countries} />
            </SimpleForm>
        </Edit>);
}
