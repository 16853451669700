import { Button,Link } from "react-admin";
import PlusOneIcon from '@mui/icons-material/PlusOne';

const AddCrmContractRelationshipButton = () => (
    <Button
        component={Link}
        to={{
            pathname: "/crm_contract_relationships/create",
        }}
        label="neue Vertragsbeziehung"
        onClick={(e) => e.stopPropagation()}
    >
        <PlusOneIcon />
    </Button>
);

export default AddCrmContractRelationshipButton;
