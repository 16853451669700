import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import authProvider from "../utils/authProvider";
import { ENTRYPOINT } from "../config/entrypoint";

const getHeaders = () => authProvider.getToken() !== null ? {
        Authorization: `Bearer ${authProvider.getToken()}`,
    } : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const apiDocumentationParser = (setRedirectToLogin) => async () => {
    try {
        setRedirectToLogin(false);

        return await parseHydraDocumentation(ENTRYPOINT, {headers: getHeaders} );
    } catch (result) {
        const {api, response, status} = result;
        if (status !== 401 || !response) {
            throw result;
        }

// Prevent infinite loop if the token is expired
        await authProvider.checkAuth();
        setRedirectToLogin(true);

        return {
            api,
            response,
            status,
        };
    }
};

const dataProvider = (setRedirectToLogin) => baseHydraDataProvider({
    entrypoint: ENTRYPOINT,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
    mercure: true,
    useEmbedded: true,
});

export default dataProvider;