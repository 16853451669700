import * as React from "react";
import {
    Create, Show, Edit,
    SimpleForm,
    SelectInput,
    DateInput,
    TextInput,
    required,
    List, SimpleShowLayout,
    Datagrid, EditButton, DeleteButton,
    DateField, ReferenceField, NumberField, BooleanField,
    TextField, RichTextField, TopToolbar, ExportButton, ListButton, FunctionField, SelectField
} from 'react-admin';
import {ctrCancellationReasonType, registeredVias} from './enums';
import {useSearchParams} from "react-router-dom";
import OpusPagination from "../components/OpusPagination";
import {makeStyles} from "@mui/styles";
import { Grid } from '@mui/material';
import ApproveButton from "../components/ApproveButton";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CtrCancellationRequestTitle = ({ abo_id, crm_contract_relationship_id, product }) => {
    return <span>Kündigungseingang `Abo {abo_id} - {crm_contract_relationship_id} - {product}`</span>;
};

const CtrCancellationRequestShowActions = () => (
    <TopToolbar>
        <ApproveButton entity="cancellation_request" action="cancel" />
        <EditButton/>
        <DeleteButton/>
        <ListButton label="Back" />
    </TopToolbar>
);

const CtrCancellationRequestActions = () => (
    <TopToolbar>
        <ExportButton/>
    </TopToolbar>
);

export const CtrCancellationRequestList = props => (
    <List
        title="Kündigungseingang "
        actions={<CtrCancellationRequestActions/>}
        pagination={<OpusPagination />}
        filter={{ 'deleted[after]': '2200-12-31' }}
        hasCreate={false}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <DateField source="effective_date" label="Effective termination date" headerClassName={useStyles().header} />
            <ReferenceField source="ctr_subscription" label="Contract until date" headerClassName={useStyles().header} link={false} >
                <DateField source="contract_until_date"/>
            </ReferenceField>
            <ReferenceField source="ctr_subscription" label="Billed Until Date"  headerClassName={useStyles().header} link={false} >
                <DateField source="billed_until_date"/>
            </ReferenceField>
            <ReferenceField source="ctr_subscription" label="Start date" headerClassName={useStyles().header} link={false} >
                <DateField source="start_date"/>
            </ReferenceField>
            <ReferenceField source="ctr_subscription" label="Vertragsbeziehung" headerClassName={useStyles().header} link={false} >
                <ReferenceField source="crm_contract_relationship" headerClassName={useStyles().header} link={(record) => '/crm_contract_relationship_views/'+encodeURIComponent(record.crm_contract_relationship.replace("relationships","relationship_views"))+'/show'} >
                    <NumberField source="originId"  headerClassName={useStyles().header} sortable={false} />{' - '}
                    <ReferenceField source="crm_venue" ><TextField source="name"/></ReferenceField>{' - '}
                    <ReferenceField source="crm_organisation" ><TextField source="legal_name"/></ReferenceField>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField source="ctr_subscription"  label="Subscription" headerClassName={useStyles().header} link={(record) => '/ctr_subscription_views/'+encodeURIComponent(record.ctr_subscription.replace("subscriptions","subscription_views"))+'/show'} >
                <ReferenceField source="ctr_product" link={false} >
                    <NumberField source="originId" /> {' - '}
                    <TextField source="name" />
                </ReferenceField>
            </ReferenceField>
            <SelectField source="reason_type" choices={ctrCancellationReasonType} headerClassName={useStyles().header} />
            <DateField source="requested_termination_date" label="Gewünschtes Kündigungsdatum" headerClassName={useStyles().header} />
            <DateField source="received_date" headerClassName={useStyles().header} />
            <TextField source="prepared_by" headerClassName={useStyles().header} />
            <EditButton/>
        </Datagrid>
    </List>
);

export const CtrCancellationRequestShow = props => (
    <Show
        title="Kündigungseingang "
        actions={<CtrCancellationRequestShowActions/>}
        pagination={<OpusPagination/>}
        hasCreate={false}
        {...props}
    >
        <Grid container spacing={2} sx={{margin: 2}}>
            <Grid item xs={12} sm={4}>
                <SimpleShowLayout>
                    <Typography variant="h6" gutterBottom>Abodaten</Typography>
                    <ReferenceField source="ctr_subscription" label="Vertragsbeziehung"
                                    headerClassName={useStyles().header}
                                    link={false}>
                        <ReferenceField source="crm_contract_relationship" headerClassName={useStyles().header}
                                        link={(record) => '/crm_contract_relationship_views/' + encodeURIComponent(record.crm_contract_relationship.replace("relationships", "relationship_views")) + '/show'}>
                            <TextField source="originId" headerClassName={useStyles().header} sortable={false}/>
                            {' - '}
                            <ReferenceField source="crm_venue" link={false}><TextField source="name"/></ReferenceField>
                            {' - '}
                            <ReferenceField source="crm_organisation" link={false}><TextField source="legal_name"/></ReferenceField>
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="Subscription" headerClassName={useStyles().header}
                                    link={(record) => '/ctr_subscription_views/' + encodeURIComponent(record.ctr_subscription.replace("subscriptions", "subscription_views")) + '/show'}>
                        <ReferenceField source="ctr_product" link={false}>
                            <NumberField source="originId"/> {' - '}
                            <TextField source="name"/>
                        </ReferenceField>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="Contract Until Date"
                                    headerClassName={useStyles().header} link={false}>
                        <DateField source="contract_until_date"/>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="Billed Until Date"
                                    headerClassName={useStyles().header}
                                    link={false}>
                        <DateField source="billed_until_date"/>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="Start Date" headerClassName={useStyles().header}
                                    link={false}>
                        <DateField source="start_date"/>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="AGB" headerClassName={useStyles().header} link={false}>
                        <TextField source="terms_and_conditions"/>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="renewal" headerClassName={useStyles().header} link={false}>
                        <BooleanField source="automatic_renewal"/>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="notice period in days" headerClassName={useStyles().header} link={false}>
                        <NumberField source="notice_period_in_days"/>
                    </ReferenceField>
                    <ReferenceField source="ctr_subscription" label="renewal period" headerClassName={useStyles().header} link={false}>
                        <TextField source="automatic_renewal_period"/>
                    </ReferenceField>
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleShowLayout>
                    <Typography variant="h6" gutterBottom>Kündigungseingang</Typography>
                    <TextField source="sent_by" label="Absender / Kontakt" headerClassName={useStyles().header}/>
                    <RichTextField source="note" headerClassName={useStyles().header}/>
                    <DateField source="received_date" label="Kündigungseingang" headerClassName={useStyles().header}/>
                    <DateField source="requested_termination_date" label="Gewünschtes Kündigungsdatum" headerClassName={useStyles().header}/>
                    <TextField source="received_via" label="Kündigung per" headerClassName={useStyles().header}/>
                    <TextField source="prepared_by" headerClassName={useStyles().header}/>
                 </SimpleShowLayout>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleShowLayout>
                    <Typography variant="h6" gutterBottom>Massnahme</Typography>
                    <DateField source="effective_date" label="Abo beenden per" headerClassName={useStyles().header}/>
                    <SelectField source="reason_type" label="Kündigungsgrund - Typ" choices={ctrCancellationReasonType} headerClassName={useStyles().header} />
                    <TextField source="reason_detail" label="Kündigungsgrund - Details" headerClassName={useStyles().header} />
                    <ApproveButton entity="cancellation_request" action="cancel" />
                    <EditButton/>
                    <DeleteButton/>
                </SimpleShowLayout>
            </Grid>
         </Grid>
    </Show>
);

export const CtrCancellationRequestCreate = (props) => {
    const [searchParams] = useSearchParams();
    let ctr_subscription_id = searchParams.get('ctr_subscription_id');
    if (!ctr_subscription_id) { return <List /> }
    let abo_id = ctr_subscription_id.slice(ctr_subscription_id.lastIndexOf('/')+1);
    const validateReason = (values) => {
        const errors = {};
        if (!values.reason_type && values.reason_detail) {
            errors.reason_type = 'Der Kündigungsgrund muss einen Typ und einen Detail Kommentar beinhalten. Bitte den Typ ergänzen.'
        }
        if (values.reason_type && !values.reason_detail) {
            errors.reason_detail = 'Der Kündigungsgrund muss einen Typ und einen Detail Kommentar beinhalten. Bitte den Detail Kommentar ergänzen.'
        }
        return errors
    }
    const transform = data => {
        if (!data.reason_type) { delete data.reason_type }
        if (!data.reason_detail) { delete data.reason_detail }

        return data
    }

    return (
        <Create
            transform={transform}
            redirect="show"
            {...props}
            title={<CtrCancellationRequestTitle
                abo_id={abo_id}
                product={searchParams.get('product')}
                crm_contract_relationship_id={searchParams.get('crm_contract_relationship_id')}
            />}
        >
            <SimpleForm
                label="Kündigung erfassen"
                defaultValues={{ ctr_subscription: ctr_subscription_id }}
                validate={validateReason}
            >
                <Typography variant="h6" gutterBottom>Kündigungseingang</Typography>
                <TextInput disabled source="ctr_subscription" label="Abo" />
                <DateInput source="received_date" validate={[required()]} />
                <SelectInput source="received_via" label="Kündigung per" choices={registeredVias} validate={[required()]}/>
                <TextInput source="sent_by" label="Absender / Kontakt" validate={[required()]}/>
                <DateInput source="requested_termination_date" label="Gewünschtes Kündigungsdatum" validate={[required()]}/>
                <TextInput source="note" label="Notizen zur Kündigung" multiline/>
                <Typography variant="h6" gutterBottom>Kündigungsgrund</Typography>
                <SelectInput source="reason_type" label="Kündigungsgrund - Typ" choices={ctrCancellationReasonType} resettable emptyValue={''}/>
                <TextInput source="reason_detail" label="Kündigungsgrund - Details" validate={[required()]} multiline/>
                <FunctionField render={() => <ApproveButton entity="cancellation_request" action="create" />}/>
            </SimpleForm>
        </Create>
    )
};

export const CtrCancellationRequestEdit = (props) => {
    const validateReason = (values) => {
        const errors = {};
        if (!values.reason_type && values.reason_detail) {
            errors.reason_type = 'Der Kündigungsgrund muss einen Typ und einen Detail Kommentar beinhalten. Bitte den Typ ergänzen.'
        }
        if (values.reason_type && !values.reason_detail) {
            errors.reason_detail = 'Der Kündigungsgrund muss einen Typ und einen Detail Kommentar beinhalten. Bitte den Detail Kommentar ergänzen.'
        }
        return errors
    }
    const transform = data => {
        if (!data.reason_type) { data.reason_type = null }
        if (!data.reason_detail) { data.reason_detail = null }

        return data
    }

    return (
        <Edit
            transform={transform}
            mutationMode="pessimistic"
            {...props}
            title={<CtrCancellationRequestTitle/>}
        >
            <SimpleForm
                validate={validateReason}
                label="Kündigung erfassen"
            >
                <Typography variant="h6" gutterBottom>Kündigungseingang</Typography>
                <TextInput disabled source="ctr_subscription" label="Abo" />
                <DateInput source="received_date" validate={[required()]} />
                <SelectInput source="received_via" label="Kündigung per" choices={registeredVias} validate={[required()]}/>
                <TextInput source="sent_by" label="Absender / Kontakt" validate={[required()]}/>
                <DateInput source="requested_termination_date" label="Gewünschtes Kündigungsdatum" validate={[required()]}/>
                <DateInput source="effective_date" validate={[required()]}/>
                <TextInput source="note" label="Notizen zur Kündigung" multiline />
                <Typography variant="h6" gutterBottom>Kündigungsgrund</Typography>
                <SelectInput source="reason_type" label="Kündigungsgrund - Typ" choices={ctrCancellationReasonType} emptyValue={''} />
                <TextInput source="reason_detail" label="Kündigungsgrund - Details" multiline />
            </SimpleForm>
        </Edit>
    )
};
