import PlusOneIcon from '@mui/icons-material/PlusOne';
import {Button, Link, useRecordContext} from "react-admin";

const AddSystemButton = ({ crmEntityId }) => {
    const record = useRecordContext();
    return <Link
        to={{
            pathname: "/crm_systems/create",
            search: `?crm_entity=${crmEntityId}&record_id=${record.id}`,
        }}
    >
        <Button
            label="Add an id from another software"
            onClick={(e) => e.stopPropagation()}
        >
            <PlusOneIcon/>
        </Button>
    </Link>
}

export default AddSystemButton;
