import { fetchUtils } from 'react-admin'
import { ENTRYPOINT } from "../config/entrypoint"
import authProvider from './authProvider'

const apiUrl = `${ENTRYPOINT}/opus21_users`

const httpClient = (url, options={}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/ld+json' });
    }
    if (authProvider.getToken()) {
        options.headers.set('Authorization', `Bearer ${authProvider.getToken()}`);
    }
    return fetchUtils.fetchJson(url, options)
}

const profileProvider = {
    getUserProfile() {
        const identity = authProvider.getUsername()
        if (!identity) {
            return Promise.resolve({ data: {} });
        }
        return httpClient(`${apiUrl}?username=${identity}`, {
            method: 'GET',
        }).then(({ json }) => ({ data: json }));
    },
    async updateUserProfile(params) {
        console.log(params)
        console.log(params.originId)
        return httpClient(`${apiUrl}/${params.originId}`, {
            method: 'PUT',
            body: JSON.stringify(params),
        }).then(({ json }) => ({
            data: { ...params, id: json.id },
        }));
    }
};

export default profileProvider;