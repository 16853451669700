import * as React from "react";
import { systemBaseUrls } from '../resources/enums';

export const UrlField = ( {record, source, baseurl} ) => {
    let link=baseurl+record[source]
    return <a
        href={link}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
    >
        {record[source]}
    </a>;
};

export const DynamicUrlField = ( (system, id) => {
    let link=systemBaseUrls.find(url => url.id === system);
    let finalLink = link ? link.name+id : id;
    return <a
        href={finalLink}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
    >
        {id}
    </a>;
});
