import * as React from "react";
import {
    Create, SimpleForm,
    TextInput, BooleanInput,
    DateInput, SelectInput, FormDataConsumer,
    useNotify,
    required, useRedirect
} from 'react-admin';
import { countries, sources, addressCategories } from "./enums";
import { useSearchParams } from "react-router-dom";

export const CrmAddressesCreate = (props) => {
    const [searchParams] = useSearchParams();
    const redirect = useRedirect();
    const crm_entity= searchParams.get('crm_entity');
    const record_id = searchParams.get('record_id');
    const redirectTo = record_id ? record_id.slice(4,record_id.indexOf('/',5))+'/'+encodeURIComponent(record_id)+'/show' : '/crm_contract_relationship_views';
    const notify = useNotify();
    const onSuccess = () => {
        notify(`Changes saved`);
        redirect(redirectTo);
    };
    return (
        <Create
            {...props}
            mutationOptions={{ onSuccess: onSuccess}}
        >
            <SimpleForm
                defaultValues={{ crm_entity: crm_entity, is_deliverable: false, source: 'admin', country: "CHE" }}
            >
                <TextInput disabled source="crm_entity" />
                <SelectInput source="category" choices={addressCategories} validate={[required()]}/>
                <SelectInput source="source" choices={sources} validate={[required()]}/>
                <TextInput source="address_line1" validate={[required()]}/>
                <TextInput source="address_line2" />
                <TextInput source="zip" validate={[required()]}/>
                <TextInput source="city" validate={[required()]}/>
                <TextInput source="canton" />
                <SelectInput source="country" label="Land" choices={countries} />
                <DateInput source="valid_from" validate={[required()]}/>
                <DateInput source="valid_until" />
                <BooleanInput source="is_deliverable" label="zustellbar" />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.is_deliverable &&
                        <DateInput source="date_of_last_check" label="letzte Prüfung" {...rest} validate={[required()]} />
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>);
}



