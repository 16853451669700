import * as React from 'react';
import {AppBar, ToggleThemeButton, defaultTheme} from "react-admin";
import Typography from "@mui/material/Typography";
import MyUserMenu from "./MyUserMenu";
const darkTheme = {
    palette: { mode: 'dark' },
};


const MyAppBar = (props) => {
    return (
            <AppBar
                {...props}
                userMenu={<MyUserMenu />}
            >
                <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
                {'prod' !== process.env.REACT_APP_ENVIRONMENT
                    && <Typography flex="1" variant="h6" id="react-admin-environment">
                            {process.env.REACT_APP_ENVIRONMENT}
                        </Typography>
                }
                <ToggleThemeButton
                    lightTheme={defaultTheme}
                    darkTheme={darkTheme}
                />
            </AppBar>
    );
}

export default MyAppBar;
