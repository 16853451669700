import React, { useState } from 'react';
import {Drawer} from "@mui/material";
import { Button, SimpleShowLayout, TextField, useGetOne } from 'react-admin';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Visibility} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    field: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        '& span': {
            display: 'inline-block',
            maxWidth: '20em'
        }
    }
});

const RelationshipQuickPreviewButton = ({ id }) => {
    const [showPanel, setShowPanel] = useState(false);
    const classes = useStyles();
    const { data } = useGetOne('crm_contract_relationship_views', { id: id });

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
        <>
            <Button onClick={handleClick} label="ra.action.show">
                <Visibility />
            </Button>
            <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
                <div>
                    <Button label="Close" onClick={handleCloseClick}>
                        <ChevronRightIcon />
                    </Button>
                </div>
                <SimpleShowLayout record={data}>
                    <TextField source="id" />
                    <TextField source="venue" className={classes.field} />
                    <TextField source="firma" className={classes.field} />
                    <TextField source="venue.current_address.zip" className={classes.field} />
                    <TextField source="venue.current_address.city" className={classes.field} />
                </SimpleShowLayout>
            </Drawer>
        </>
    );
};


export default RelationshipQuickPreviewButton;