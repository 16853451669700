import PlusOneIcon from '@mui/icons-material/PlusOne';
import {Button, Link, useRecordContext} from "react-admin";

const AddAddressButton = ({ crmEntityId }) => {
    const record = useRecordContext();

    return <Link
        to={{
            pathname: "/crm_addresses/create",
            search: `crm_entity=${crmEntityId}&record_id=${ record.id }`,
        }}
    >
        <Button
            label="Add an address"
            onClick={(e) => e.stopPropagation()}
        >
            <PlusOneIcon />
        </Button>
    </Link>
};

export default AddAddressButton;
