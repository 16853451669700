import { Button, Link, useRecordContext } from "react-admin";
import CancelIcon from '@mui/icons-material/Cancel';

const AddCancellationReasonButton = () => {
    const record = useRecordContext();

    return !record.type && <Button
        component={Link}
        to={{
            pathname: "/ctr_cancellation_reasons/create",
            search: `?record_id=${record.id}&crm_contract_relationship_id=${record.crm_contract_relationship_id}&product=${record.product}`,
        }}
        label="Kündigungsgrund erfassen"
        onClick={(e) => e.stopPropagation()}
    >
        <CancelIcon />
    </Button>
}

export default AddCancellationReasonButton;
