import * as React from "react";
import {
    FunctionField,
    Datagrid,
    TextField,
    List, Filter, TextInput, TopToolbar, SelectInput, downloadCSV, Button
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import CalculateAccrualsButton from "../components/CalculateAccrualsButton";
import {ctrCurrency} from "./enums";
import OpusPagination from "../components/OpusPagination";
import processProvider from "../utils/processProvider";

const CalcAccrualTransactionViewListActions = () => (
    <TopToolbar>
        <CalculateAccrualsButton/>
        <ExportButton/>
    </TopToolbar>
);

const ExportButton = () => {
    const handleClick = () => processProvider.exportCalcAccrualTransactionViews().then((csv) => downloadCSV(csv, 'accrual_transactions'))

    return <Button onClick={handleClick}>Export</Button>
}

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CalcAccrualTransactionViewFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Rechnungsmonat" source="invoice_month" alwaysOn  />
        <TextInput label="Abgrenzungsmonat" source="accrual_month" alwaysOn />
        <TextInput label="Konto" source="account" alwaysOn />
        <SelectInput label="Währung" source="currency" choices={ctrCurrency} alwaysOn />
    </Filter>
);

export const CalcAccrualTransactionViewList = props => (
    <List
        title="Abgrenzungen - Buchungen"
        pagination={<OpusPagination />}
        perPage={25}
        filters={<CalcAccrualTransactionViewFilter/>}
        actions={<CalcAccrualTransactionViewListActions/>}
        {...props}
    >
        <Datagrid rowClick="show"  >
            <TextField source="invoice_month" label="Rechnungsmonat" headerClassName={useStyles().header}/>
            <TextField source="accrual_month" label="Abgrenzungsmonat" headerClassName={useStyles().header}/>
            <TextField source="currency" label="Währung" headerClassName={useStyles().header}/>
            <TextField source="account" label="Konto" headerClassName={useStyles().header}/>
            <FunctionField label="Betrag" render={record => (record.amount/100).toFixed(2)} headerClassName={useStyles().header}/>
        </Datagrid>
    </List>
);





