import * as React from "react";
import {
    Create, useNotify,
    SimpleForm,
    TextInput,
    DateInput, SelectInput,
    required, useRedirect,
} from 'react-admin';
import { sources, contactCategories } from "./enums";
import {useSearchParams} from "react-router-dom";


export const CrmPhoneNumbersCreate = (props) => {
    const [searchParams] = useSearchParams();
    const redirect = useRedirect();
    const crm_entity= searchParams.get('crm_entity');
    const record_id = searchParams.get('record_id');
    const redirectTo = record_id ? record_id.slice(4,record_id.indexOf('/',5))+'/'+encodeURIComponent(record_id)+'/show' : '/crm_contract_relationship_views';
    const notify = useNotify();
    const onSuccess = () => {
        notify(`Changes saved`);
        redirect(redirectTo);
    };

    return (
        <Create
            mutationMode="pessimistic"
            {...props}
            mutationOptions={{
                onSuccess: onSuccess
            }}
        >
            <SimpleForm
                defaultValues={{ crm_entity: crm_entity, source: 'admin' }}>
                <TextInput disabled source="crm_entity" />
                <SelectInput source="category" choices={contactCategories} validate={[required()]}/>
                <SelectInput source="source" choices={sources} validate={[required()]}/>
                <TextInput source="phone_number" />
                <DateInput source="valid_from" validate={[required()]}/>
                <DateInput source="valid_until" />
            </SimpleForm>
        </Create>);
}



