import * as React from "react";
import {
    DateField,
    List,
    Datagrid,
    NumberField, TextField, Filter, TextInput
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const MonJobFilter =  (props) => (
    <Filter {...props}>
        <TextInput source="job" alwaysOn  />
        <TextInput source="status" alwaysOn />
    </Filter>
);

export const MonJobList = props => (
    <List
        filters={<MonJobFilter />}
        title="Job Log" {...props}
        sort={{field: 'id', order: 'DESC'}}
        pagination={<OpusPagination />}
        perPage={25}
    >
        <Datagrid bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="job" headerClassName={useStyles().header} />
            <DateField source="start_dttm" headerClassName={useStyles().header} showTime={true} />
            <DateField source="end_dttm" headerClassName={useStyles().header} showTime={true} />
            <TextField source="status" headerClassName={useStyles().header} />
            <TextField source="error_payload" headerClassName={useStyles().header} />
        </Datagrid>
    </List>
);
