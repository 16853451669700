import * as React from "react";
import {
    Button,downloadCSV,
    Create,
    SimpleForm,
    Show,
    List,
    SelectInput,
    ReferenceInput,
    required,
    useNotify,
    Edit,Labeled,
    EditButton,
    SimpleShowLayout,
    NumberInput,
    TextField,
    Datagrid,
    DateField,
    RichTextField,
    FunctionField,
    NumberField,
    DateInput,
    BooleanField,
    TextInput,
    BooleanInput,
    TopToolbar,
    ListButton,
    Filter,
    ReferenceManyField,
    CreateButton,
    useRecordContext,
    useRedirect,
    useRefresh,
    useEditContext,
    ReferenceField
} from 'react-admin';
import RelationshipReferenceInput from '../components/RelationshipReferenceInput';
import PlanReferenceInput from '../components/PlanReferenceInput';
import CtrProductReferenceInput from "../components/CtrProductReferenceInput";
import {makeStyles} from "@mui/styles";
import {ctrCurrency, period, units} from "./enums";
import PrepareRenewalsAndInstallmentsButton from "../components/PrepareRenewalsAndInstallmentsButton";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";
import OpusPagination from "../components/OpusPagination";
import {RichTextInput} from "ra-input-rich-text";
import {useSearchParams} from "react-router-dom";
import ApproveButton from "../components/ApproveButton";
import AddNewContractDraftItemButton from "../components/AddNewContractDraftItemButton";
import {DEFAULT_CONTACT_PERSON} from "../config/entrypoint";
import processProvider from "../utils/processProvider";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const ExportButton = () => {
    const handleClick = () => processProvider.exportContractDrafts().then((csv) => downloadCSV(csv, 'contract_drafts'))

    return <Button onClick={handleClick}>Export</Button>
}

const CtrContractDraftFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Vertragsbeziehung" source="crm_contract_relationship.id" alwaysOn  />
        <TextInput label="Venue" source="crm_contract_relationship.crm_venue.name" alwaysOn />
        <TextInput label="Firma" source="crm_contract_relationship.crm_organisation.legal_name" alwaysOn />
        <TextInput label="Gastrogruppe" source="crm_contract_relationship.crm_group.name" alwaysOn />
        <SelectInput label="Währung" source="currency" choices={ctrCurrency} alwaysOn />
    </Filter>
);

const CtrContractDraftTitle = () => {
    const record = useRecordContext();

    return <span>Vertragsentwurf {record ? `#${record.originId}: Vertragsbeziehung ${record.crm_contract_relationship.substring(record.crm_contract_relationship.lastIndexOf("/")+1)}` : ''}</span>;
};

const CtrContractDraftListActions = () => (
    <TopToolbar>
        <PrepareRenewalsAndInstallmentsButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const CtrContractDraftEditActions = () => (
    <TopToolbar>
        <ListButton resource="ctr_contract_drafts" label="Back" />
        <AddNewContractDraftItemButton />
    </TopToolbar>
);

const CtrContractDraftShowActions = () => (
    <TopToolbar>
        <ListButton basepath="/ctr_contract_drafts" label="Back" />
        <EditButton />
        <ApproveButton entity="contract_draft" action="create" />
        <AddNewContractDraftItemButton />
   </TopToolbar>
);

export const CtrContractDraftCreate = (props) => {
    const transform = data => ({
        ...data,
        crm_contract_relationship: data.crm_contract_relationship.replace('_views','s'),
    });
    const defaultValues = { currency: 'CHF', ctr_contract_person: DEFAULT_CONTACT_PERSON }

    return <Create
        transform={transform}
        mutationMode="pessimistic"
        redirect="show"
        {...props}
    >
        <SimpleForm
            label="Bestellformular erfassen"
            redirect={"show"}
            defaultValues={ defaultValues }
            sx={{ maxWidth: 1200}}
        >
            <RelationshipReferenceInput source="crm_contract_relationship" reference="crm_contract_relationship_views" validate={[required()]} fullWidth />
            <PlanReferenceInput source="ctr_plan" reference="ctr_plans" validate={[required()]} fullWidth />
            <DateInput source="start_date" validate={[required()]}  name="start_date" fullWidth />
            <SelectInput name="currency" field="currency" source="currency" choices={ctrCurrency} validate={[required()]} fullWidth />
            <ReferenceInput source="ctr_contact_person" reference="ctr_contact_people" >
                <SelectInput label="Kontaktperson" optionText="email" validate={[required()]} fullWidth />
            </ReferenceInput>
            <RichTextInput source="notes" name="notes" fullWidth />
        </SimpleForm>
    </Create>
};

export const CtrContractDraftList = props => (
    <List
        title="Vertragsentwürfe "
        actions={<CtrContractDraftListActions/>}
        filters={<CtrContractDraftFilter/>}
        filter={{ 'deleted[after]': '2200-12-31' }}
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <ReferenceField
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.id"
                label="Vertragsbeziehung"
                headerClassName={useStyles().header}
                link={false}
            >
                <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
            </ReferenceField>
            <ReferenceField
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.crm_venue.name"
                label="Venue"
                headerClassName={useStyles().header}
                link={false}
            >
                <ReferenceField source="crm_venue"  link={false}><TextField source="name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.crm_organisation.legal_name"
                label="Organisation"
                headerClassName={useStyles().header}
                link={false}
            >
                <ReferenceField source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField
                source="crm_contract_relationship"
                sortBy="crm_contract_relationship.crm_group.name"
                label="Gastrogruppe"
                headerClassName={useStyles().header}
                link={false}
            >
                <FunctionField render={record => record.crm_group && <ReferenceField source="crm_group"  link={false}><TextField source="name"/></ReferenceField>} />
            </ReferenceField>
            <FunctionField
                render={record => record.ctr_plan && <ReferenceField source="ctr_plan" link={false} ><TextField source="name"/></ReferenceField>}
                headerClassName={useStyles().header}
                label="Plan"
            />
            <DateField source="start_date" headerClassName={useStyles().header} sortable={false} />
            <TextField source="currency" headerClassName={useStyles().header} sortable={false} />
            <FunctionField label="Betrag" render={record => (record.contract_total/100).toFixed(2)} headerClassName={useStyles().header} sortable={false} />
            <ReferenceField source="ctr_contact_person" reference="ctr_contact_people" label="Kontaktperson" headerClassName={useStyles().header} link={false} >
                <TextField source="email" validate={[required()]} fullWidth />
            </ReferenceField>
            <TextField source="prepared_by" headerClassName={useStyles().header} sortable={false} />
            <RichTextField source="notes" headerClassName={useStyles().header} sortable={false} />
            <DateField source="prepared" headerClassName={useStyles().header} sortable={false} />
            <DateField source="created" headerClassName={useStyles().header} sortable={false} />
            <DateField source="modified" headerClassName={useStyles().header} sortable={false} />
            <EditButton />
            <FunctionField render={() => <ApproveButton entity="contract_draft" action="create" />}/>
        </Datagrid>
    </List>
);

    export const CtrContractDraftShow = (props) => (
        <Show {...props} title={<CtrContractDraftTitle />} actions={<CtrContractDraftShowActions/>}>
            <SimpleShowLayout>
                <ReferenceField source="crm_contract_relationship" label="Vertragsbeziehung" headerClassName={useStyles().header} link={false} >
                    <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
                </ReferenceField>
                <ReferenceField source="crm_contract_relationship" label="Organisation" headerClassName={useStyles().header} link={false} >
                    <ReferenceField source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
                </ReferenceField>
                <ReferenceField source="crm_contract_relationship" label="Venue" headerClassName={useStyles().header} link={false} >
                    <ReferenceField source="crm_venue"  link={false}><TextField source="name"/></ReferenceField>
                </ReferenceField>
                <ReferenceField source="crm_contract_relationship" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                    <FunctionField render={record => record.crm_group && <ReferenceField source="crm_group"  link={false}><TextField source="name"/></ReferenceField>} />
                </ReferenceField>
                <FunctionField  label="Rechnungsadresse" render={record => {
                    if(!record.billing_address) {
                        return <div><strong>!!!!! RECHNUNGSADRESSE MUSS NOCH ERFASST WERDEN !!!!!</strong></div>
                    } else {
                        return <ReferenceField reference="crm_addresses" source="billing_address" link={false} >
                            <TextField source="address_line1"/>{',' }<TextField source="address_line2"/>{', '}<TextField source="zip"/>{', '}<TextField source="city"/>{', '}<TextField source="country"/>
                        </ReferenceField>}
                    }
                }/>
                <TextField source="currency" headerClassName={useStyles().header} />
                <ReferenceManyField reference="ctr_contract_draft_items" target="ctr_contract_draft" source="id" label="Items" sort={{field: 'id', order:'asc'}}>
                    <Datagrid rowClick="edit" bulkActionButtons={false} >
                        <TextField label="Id" source="originId" />
                        <TextField source="installment" />
                        <BooleanField source="is_contractual" />
                        <ReferenceField source="ctr_product" reference="ctr_products" label="Produkt"  link={false} >
                            <TextField source="name"/>
                        </ReferenceField>
                        <FunctionField label="total" render={record => (record.position_total/100).toFixed(2)} />
                        <FunctionField label="price" render={record => (record.price/100).toFixed(2)} />
                        <FunctionField label="rebate" render={record => (record.rebate/100).toFixed(2)} />
                        <FunctionField label="rebate_percent" render={record => (record.rebate_percent/1000000).toFixed(2)} />
                        <NumberField source="number_of_units" />
                        <TextField source="unit" />
                        <RichTextField source="text_template" />
                        <DateField source="start_date" />
                        <DateField source="end_date" />
                        <DateField source="created" />
                        <DateField source="modified" />
                    </Datagrid>
                </ReferenceManyField>
                <ReferenceField source="crm_contract_relationship" label="Organisation" headerClassName={useStyles().header} link={false} >
                    <ReferenceField source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
                </ReferenceField>
                <ReferenceField source="crm_contract_relationship" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                    <FunctionField render={record => record.crm_group && <ReferenceField source="crm_group"  link={false}><TextField source="name"/></ReferenceField>} />
                </ReferenceField>
                <TextField source="ctr_plan" headerClassName={useStyles().header} />
                <DateField source="start_date" />
                <BooleanField source="automatic_renewal" />
                <TextField source="automatic_renewal_period" />
                <TextField source="cancellation" />
                <NumberField source="notice_period_in_days" />
                <TextField source="billing_period" />
                <TextField source="terms_and_conditions" />
                <TextField source="prepared_by" />
                <RichTextField source="notes" />
                <DateField source="prepared" />
                <FunctionField render={() => <ApproveButton entity="contract_draft" action="create" />}/>
            </SimpleShowLayout>
        </Show>
    );

export const CtrContractDraftEdit = (props) =>  <Edit
        mutationMode="pessimistic"
        {...props}
        actions={<CtrContractDraftEditActions/>}
        sx={{ maxWidth: 1200}}
    >
        <SimpleForm>
            <TextInput disabled source="crm_contract_relationship" fullWidth />
            <TextInput disabled source="ctr_plan" fullWidth />
            <ReferenceInput source="ctr_contact_person" reference="ctr_contact_people" >
                <SelectInput label="Kontaktperson" optionText="email" validate={[required()]} fullWidth />
            </ReferenceInput>
            <SelectInput name="currency" source="currency" choices={ctrCurrency} validate={[required()]} fullWidth />
            <DateInput validate={[required()]} source="start_date" fullWidth />
            <BooleanInput validate={[required()]} source="automatic_renewal" fullWidth />
            <SelectInput validate={[required()]} source="automatic_renewal_period" choices={period} fullWidth />
            <TextInput disabled validate={[required()]} source="cancellation" fullWidth />
            <NumberInput validate={[required()]} source="notice_period_in_days" fullWidth />
            <SelectInput validate={[required()]} source="billing_period" choices={period} fullWidth />
            <TextInput validate={[required()]} source="terms_and_conditions" fullWidth />
            <RichTextInput source="notes" fullWidth />
            <DateInput disabled source="created" fullWidth />
            <DateInput disabled source="modified" fullWidth />
        </SimpleForm>
    </Edit>;

export const CtrContractDraftItemEdit = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    const context = useEditContext();

    const onSuccess = () => {
        console.log(context);
        notify(`Changes saved`);
        redirect('/ctr_contract_drafts/' );
        refresh();
    };

    return (
        <Edit
            mutationMode="pessimistic"
            actions={<CtrContractDraftEditActions />}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm sx={{ maxWidth: 1200}}>
                <Labeled label="Produkt">
                    <ReferenceField source="ctr_product" reference="ctr_products" label="Produkt" headerClassName={useStyles().header} link={false} fullWidth >
                        <TextField source="name"/>
                    </ReferenceField>
                </Labeled>
                <Labeled label="id">
                    <TextField disabled source="id" fullWidth />
                </Labeled>
                <NumberInput validate={[required()]} source="installment" fullWidth />
                <BooleanInput validate={[required()]} source="is_contractual" fullWidth />
                <NumberInput  validate={[required()]} label="price" source="price" format={v => v / 100} parse={v => parseFloat(v) * 100} fullWidth />
                <NumberInput validate={[required()]} label="rebate" source="rebate"  format={v => v / 100} parse={v => parseFloat(v) * 100} fullWidth />
                <NumberInput validate={[required()]} label="rebate_percent" source="rebate_percent" format={v => v / 1000000} parse={v => parseFloat(v) * 1000000} fullWidth />
                <NumberInput validate={[required()]} source="number_of_units" fullWidth />
                <SelectInput validate={[required()]} source="unit" choices={units} fullWidth />
                <RichTextInput  validate={[required()]}source="text_template" fullWidth />
                <DateInput  validate={[required()]} source="start_date" fullWidth />
                <DateInput source="end_date" fullWidth />
            </SimpleForm>
        </Edit>)
};


export const CtrContractDraftItemCreate = () => {
    const [searchParams] = useSearchParams();
    const redirect = useRedirect();
    const ctrContractDraft = searchParams.get('record_id');
    const redirectTo = '/ctr_contract_drafts/'+encodeURIComponent(ctrContractDraft)+'/show';
    const notify = useNotify();
    const onSuccess = () => {
        notify('Item created');
        redirect(redirectTo);
    }

    return (
        <Create
            mutationMode="pessimistic"
            title="Position zu Vertragsentwurf erfassen"
            mutationOptions={{ onSuccess: onSuccess }}
        >
            <SimpleForm
                defaultValues={{ctr_contract_draft: ctrContractDraft, rebate: 0, rebate_percent: 0, is_contractual: true }}
                sx={{ maxWidth: 1200}}
                >
                <TextInput disabled source="ctr_contract_draft" fullWidth />
                <NumberInput validate={[required()]} source="installment" fullWidth />
                <BooleanInput validate={[required()]} source="is_contractual" fullWidth />
                <CtrProductReferenceInput validate={[required()]} source="ctr_product" reference="ctr_products" fullWidth />
                <NumberInput validate={[required()]} source="price" format={v => v / 100} parse={v => Math.round(parseFloat(v) * 100)} fullWidth />
                <NumberInput validate={[required()]} source="rebate"  format={v => v / 100} parse={v => Math.round(parseFloat(v) * 100)} fullWidth />
                <NumberInput validate={[required()]} source="rebate_percent" format={v => v / 1000000} parse={v => Math.round(parseFloat(v) * 1000000)} fullWidth />
                <NumberInput validate={[required()]} source="number_of_units" fullWidth />
                <SelectInput validate={[required()]} source="unit" choices={units} fullWidth />
                <RichTextInput validate={[required()]} source="textTemplate" width={600} />
                <DateInput validate={[required()]} source="start_date" fullWidth />
                <DateInput source="end_date" fullWidth />
            </SimpleForm>
        </Create>
    )
};
