import React, { useState } from 'react';
import {Drawer} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { Button, SimpleShowLayout, TextField, useGetOne } from 'react-admin';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Visibility} from "@mui/icons-material";

const useStyles = makeStyles({
    field: {
        '& span': {
            display: 'inline-block',
            maxWidth: '20em'
        }
    }
});

const OrganisationQuickPreviewButton = ({ id }) => {
    const [ showPanel, setShowPanel ] = useState();
    const classes = useStyles();
    const { data } = useGetOne('crm_organisations', { id: id });

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
        <>
            <Button onClick={handleClick} label="ra.action.show">
                <Visibility />
            </Button>
            <Drawer anchor="right" open={ showPanel } onClose={handleCloseClick}>
                <div>
                    <Button label="Close" onClick={handleCloseClick}>
                        <ChevronRightIcon />
                    </Button>
                </div>
                <SimpleShowLayout record={data}>
                    <TextField source="id" />
                    <TextField source="legal_name" className={classes.field} />
                    <TextField source="current_billing_address.zip" className={classes.field} />
                    <TextField source="current_billing_address.city" className={classes.field} />
                </SimpleShowLayout>
            </Drawer>
        </>
    );
};

export default OrganisationQuickPreviewButton;
