import * as React from "react";
import {
    Create,
    SimpleForm,
    SelectInput,
    TextInput,
    required,
    useRedirect,
    useNotify
} from 'react-admin';
import { ctrCancellationReasonType } from "./enums";
import { useSearchParams} from "react-router-dom";

const CtrCancellationReasonsTitle = ({ crm_contract_relationship_id, product }) => {
    return <span>Kündigungsgrund: Vertragsbeziehung #{crm_contract_relationship_id} - {product}`</span>;
};


export const CtrCancellationReasonCreate = (props) => {
    const [searchParams] = useSearchParams();
    const record_ld_id = searchParams.get('record_id');
    const record_id = record_ld_id.slice(record_ld_id.indexOf('/',5)+1);
    const ctr_cancellation_id = '/api/ctr_cancellations/'+record_id;
    const redirectTo = '/ctr_cancellation_views/' + encodeURIComponent(record_ld_id) + '/show';
    const redirect = useRedirect();
    const notify = useNotify();
    const onSuccess = () => {
        notify(`Changes saved`);
        redirect(redirectTo);
    };

    return (
        <Create
            {...props}
            mutationMode="pessimistic"
            mutationOptions={{
                onSuccess: onSuccess
            }}
            title={<CtrCancellationReasonsTitle
                    crm_contract_relationship_id={searchParams.get('crm_contract_relationship_id')}
                    product={searchParams.get('product')}/>}
        >
            <SimpleForm label="Kündigungsgrund erfassen"
                defaultValues={{ ctr_cancellation: ctr_cancellation_id }} >
                <TextInput disabled source="ctr_cancellation" label="Kündigung" />
                <SelectInput source="type" label="Kündigungsgrund - Typ" choices={ctrCancellationReasonType} validate={[required()]}/>
                <TextInput source="detail" label="Kündigungsgrund - Details" validate={[required()]} multiline />
            </SimpleForm>
        </Create>
    )
};

export const CtrCancellationReasonEdit = (props) => {
    const [searchParams] = useSearchParams();
    const ctr_cancellation_id = searchParams.get('ctr_cancellation');
    const ctrCancellation = ctr_cancellation_id ? '/api/ctr_cancellations/'+ctr_cancellation_id : "";
    return (
        <Create mutationMode="pessimistic" redirect="show" {...props} title={<CtrCancellationReasonsTitle />} >
            <SimpleForm label="Kündigungsgrund erfassen"
                defaultValues={{ ctr_cancellation: ctrCancellation }} >
                <TextInput disabled source="ctr_cancellation" label="Kündigung" />
                <SelectInput source="type" label="Kündigungsgrund - Typ" choices={ctrCancellationReasonType} validate={[required()]}/>
                <TextInput source="detail" label="Kündigungsgrund - Details" validate={[required()]} multiline />
            </SimpleForm>
        </Create>
    )
};