import * as React from "react";
import {
    Filter,
    TextInput,
    Show,
    List,
    SelectField,
    TabbedShowLayout,
    Tab,
    TextField,
    Datagrid,
    DateField,
    RichTextField,
    TopToolbar,
    ListButton,
    ReferenceManyField,
    BooleanField,
    FunctionField, useRecordContext, downloadCSV, Button, DateInput
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import CancellationButton from "../components/CancellationButton";
import { ctrProducts } from './enums';
import PrepareRenewalsAndInstallmentsButton from "../components/PrepareRenewalsAndInstallmentsButton";
import OpusPagination from "../components/OpusPagination";
import processProvider from "../utils/processProvider";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CtrSubscriptionViewTitle = () => {
    const record = useRecordContext();

    return <span>Abo {record ? `#${record.originId} - ${record.crm_contract_relationship_id} - ${record.product}` : ''}</span>;
};

const CtrSubscriptionViewListActions = () => (
    <TopToolbar>
        <PrepareRenewalsAndInstallmentsButton />
        <ExportButton />
    </TopToolbar>
);

const ExportButton = () => {
    const handleClick = () => processProvider.exportSubscriptions().then((csv) => downloadCSV(csv, 'subscriptions'))

    return <Button onClick={handleClick}>Export</Button>
}

const CtrSubscriptionViewShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
    </TopToolbar>
);

const CtrSubscriptionViewFilter = (props) => (
    <Filter {...props}>
        <TextInput label="CrmContractRelationship" source="crm_contract_relationship_id" alwaysOn  />
        <TextInput label="Venue" source="venue" alwaysOn />
        <TextInput label="Firma" source="firma" alwaysOn />
        <TextInput label="Gastrogruppe" source="gastrogruppe" alwaysOn />
        <TextInput label="Produkt" source="product" alwaysOn />
        <DateInput label="StartDateAfter" source="start_date[after]" alwaysOn />
        <DateInput label="StartDateBefore" source="start_date[before]" alwaysOn />
        <DateInput label="BilledUntilMonthAfter" source="billed_until_date[after]" alwaysOn />
        <DateInput label="BilledUntilMonthBefore" source="billed_until_date[before]" alwaysOn />
        <DateInput label="ContractUntilMonthAfter" source="contract_until_date[after]" alwaysOn  />
        <DateInput label="ContractUntilMonthBefore" source="contract_until_date[before]" alwaysOn  />
        <DateInput label="EndMonthAfter" source="end_date[after]" alwaysOn />
        <DateInput label="EndMonthBefore" source="end_date[before]" alwaysOn />
     </Filter>
);


export const CtrSubscriptionViewList = props => (
    <List
        filters={<CtrSubscriptionViewFilter />}
        actions={<CtrSubscriptionViewListActions/>}
        title="Abos "
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid optimized rowClick="show" bulkActionButtons={false} >
            <TextField source="originId" label="Abo#" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="crm_contract_relationship_id" headerClassName={useStyles().header} />
            <TextField source="venue" headerClassName={useStyles().header} />
            <TextField source="firma" headerClassName={useStyles().header} />
            <TextField source="gastrogruppe" headerClassName={useStyles().header} />
            <TextField source="product" headerClassName={useStyles().header} />
            <BooleanField source="automatic_renewal" headerClassName={useStyles().header} />
            <TextField source="automatic_renewal_period" headerClassName={useStyles().header} />
            <TextField source="cancellation" headerClassName={useStyles().header} />
            <TextField source="notice_period_in_days" headerClassName={useStyles().header} />
            <TextField source="billing_period" headerClassName={useStyles().header} />
            <TextField source="terms_and_conditions" headerClassName={useStyles().header} />
            <DateField source="start_date" headerClassName={useStyles().header} />
            <DateField source="billed_until_date" headerClassName={useStyles().header} />
            <DateField source="contract_until_date" headerClassName={useStyles().header} />
            <DateField source="end_date" headerClassName={useStyles().header} />
            <FunctionField render={record => <CancellationButton record={record} />}/>
        </Datagrid>
    </List>
);

export const CtrSubscriptionViewShow = () => (
    <Show
        title={<CtrSubscriptionViewTitle />}
        actions={<CtrSubscriptionViewShowActions/>}
    >
        <TabbedShowLayout>
            <Tab label="Abo">
                <TextField source="crm_contract_relationship_id"/>
                <TextField source="ctr_subscription_id"/>
                <TextField source="product" />
                <DateField source="start_date" />
                <DateField source="contract_until_date" />
                <DateField source="end_date" />
                <RichTextField source="note" />
            </Tab>
            <Tab label="Vertragsbeziehung">
                <TextField source="crm_contract_relationship_id" />
                <TextField source="venue"/>
                <TextField source="firma"/>
                <ReferenceManyField reference="ctr_subscription_views" label="Abos" target="crm_contract_relationship_id" source="crm_contract_relationship_id" >
                    <Datagrid>
                        <TextField source="id" headerClassName={useStyles().header} />
                        <SelectField source="ctr_product" choices={ctrProducts} headerClassName={useStyles().header} />
                        <DateField source="start_date" headerClassName={useStyles().header} />
                        <DateField source="contract_until_date" headerClassName={useStyles().header} />
                        <DateField source="end_date" headerClassName={useStyles().header} />
                        <CancellationButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
