import { fetchUtils } from 'react-admin';
import { ENTRYPOINT } from "../config/entrypoint";
import authProvider from './authProvider';

const apiUrl = ENTRYPOINT;

const httpClient = (url, options={}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/ld+json' });
    }
    if (authProvider.getToken()) {
        options.headers.set('Authorization', `Bearer ${authProvider.getToken()}`);
    }
    return fetchUtils.fetchJson(url, options)
}

const processProvider =  {
    exportSubscriptions: () => {
        const state = JSON.parse(localStorage.getItem("RaStore.ctr_subscription_views.listParams"));
        console.log("State is:")
        console.log(state)
        let querystring = ''
        if (state && state.filter) {
            querystring = '?'
            if (state.filter.crm_contract_relationship_id) {
                querystring = querystring + 'crm_contract_relationship_id=' + state.filter.crm_contract_relationship_id
            }
            if (state.filter.venue) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'venue='+state.filter.venue
            }
            if (state.filter.firma) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'firma='+state.filter.firma
            }
            if (state.filter.gastrogruppe) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'gastrogruppe='+state.filter.gastrogruppe
            }
            if (state.filter.product) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'product='+state.filter.product
            }
        }
        return httpClient(`${apiUrl}/export/ctr_subscriptions`+querystring, {
            method: 'GET',
            headers: new Headers({ Accept: 'text/csv; charset=utf-8' })
        }).then((r) => r.body);
    },
    exportCancellations: () => {
        return httpClient(`${apiUrl}/export/ctr_cancellations`, {
            method: 'GET',
            headers: new Headers({ Accept: 'text/csv; charset=utf-8' })
        }).then((r) => r.body);
    },
    exportCalcAccrualTransactionViews: () => {
        const state = JSON.parse(localStorage.getItem("RaStore.calc_accrual_transaction_views.listParams"));
        let querystring = ''
        if (state && state.filter) {
            querystring = '?'
            if (state.filter.account) {
                querystring = querystring + 'account=' + state.filter.account
            }
            if (state.filter.invoice_month) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'invoice_month='+state.filter.invoice_month
            }
            if (state.filter.accrual_month) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'accrual_month='+state.filter.accrual_month
            }
            if (state.filter.currency) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'currency='+state.filter.currency
            }
        }
        return httpClient(`${apiUrl}/export/calc_accrual_transaction_views`+querystring, {
            method: 'GET',
            headers: new Headers({ Accept: 'text/csv; charset=utf-8' })
        }).then((r) => r.body);
    },
    exportCalcInvoiceItemViews: () => {
        const state = JSON.parse(localStorage.getItem("RaStore.calc_invoice_item_views.listParams"));
        let querystring = ''
        if (state && state.filter) {
            querystring = '?'
            if (state.filter.account) {
                querystring = querystring + 'account=' + state.filter.account
            }
            if (state.filter.invoice_month) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'invoice_month='+state.filter.invoice_month
            }
            if (state.filter.accrual_month) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'accrual_month='+state.filter.accrual_month
            }
            if (state.filter.currency) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'currency='+state.filter.currency
            }
        }
        return httpClient(`${apiUrl}/export/calc_invoice_item_views`+querystring, {
            method: 'GET',
            headers: new Headers({ Accept: 'text/csv; charset=utf-8' })
        }).then((r) => r.body);
    },
    exportCalcAccruedRevenues: () => {
        const state = JSON.parse(localStorage.getItem("RaStore.calc_invoice_item_views.listParams"));
        let querystring = ''
        if (state && state.filter) {
            querystring = '?'
            if (state.filter.account) {
                querystring = querystring + 'account=' + state.filter.account
            }
            if (state.filter.invoice_month) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'invoice_month='+state.filter.invoice_month
            }
            if (state.filter.accrual_month) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'accrual_month='+state.filter.accrual_month
            }
            if (state.filter.currency) {
                querystring = querystring + (querystring === '?' ? '' : '&') + 'currency='+state.filter.currency
            }
        }
        return httpClient(`${apiUrl}/export/calc_accrued_revenues`+querystring, {
            method: 'GET',
            headers: new Headers({ Accept: 'text/csv; charset=utf-8' })
        }).then((r) => r.body);
    },
    exportContractDrafts: () => {
        return httpClient(`${apiUrl}/export/ctr_contract_drafts`, {
            method: 'GET',
            headers: new Headers({ Accept: 'text/csv; charset=utf-8' })
        }).then((r) => r.body);
    },
    exportMeteredInvoices: () => {
        return httpClient(`${apiUrl}/export/ctr_stage_metered_invoices`, {
            method: 'GET',
            headers: new Headers({ Accept: 'text/csv; charset=utf-8' })
        }).then((r) => r.body);
    },
    createContractLedger: (params) => {
        return httpClient(`${apiUrl}/ctr_contract_ledgers`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },
    createSubscriptionLedger: (params) => {
        return httpClient(`${apiUrl}/ctr_subscription_ledgers`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },
    searchUid: (params) => {
        return httpClient(`${apiUrl}/uid_search/${params.search_string}`, {
            method: 'GET',
        }).then(({ json }) => ({ data: json }));
    },
    triggerProcess: (resource, params) => {
        return httpClient(`${apiUrl}/process/${resource}`, {
            method: 'GET'
        }).then(({ json }) => ({ data: json }));
    },
    pingenSend: (ctr_invoice_id) => {
        return httpClient(`${apiUrl}/pingen/send/${ctr_invoice_id}`, {
            method: 'GET'
        }).then(({ json }) => ({ data: json }));
    },
    bexioSend: (ctr_invoice_id) => {
        return httpClient(`${apiUrl}/bexio/email_invoice/${ctr_invoice_id}`, {
            method: 'GET'
        }).then(({ json }) => ({ data: json }));
    },
    softDelete: ( record ) => {
        switch (record.id.slice(5, record.id.indexOf('/',5))) {
            case 'crm_addresses':
            case 'crm_emails':
            case 'crm_phone_numbers':
            case 'crm_systems':
            case 'ctr_plans':
            case 'ctr_products':
            case 'ctr_plan_items':
                let validUntil = { valid_until: new Date()};
                return httpClient(`${apiUrl}/${record.id.slice(5)}`, {
                    method: 'PUT',
                    body: JSON.stringify(validUntil),
                }).then(({ json }) => ({ data: json })) ;
            default:
                throw new Error('sof delete not possible for this resource.')
        }
    },
};

export default processProvider;
