import { HydraAdmin } from "@api-platform/admin";
import * as React from "react";
import authProvider from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";
import {Resource, defaultTheme, CustomRoutes} from 'react-admin';
import {UserList, UserCreate, UserEdit} from "./resources/opus21_users";
import {
    CrmOrganisationCreate,
    CrmOrganisationEdit,
    CrmOrganisationList,
    CrmOrganisationShow
} from "./resources/crm_organisations";
import {CrmVenueCreate, CrmVenueEdit, CrmVenueList, CrmVenueShow} from "./resources/crm_venues";
import {CrmGroupCreate, CrmGroupEdit, CrmGroupList, CrmGroupShow} from "./resources/crm_groups";
import {CrmAddressesCreate} from "./resources/crm_addresses";
import {CrmPhoneNumbersCreate} from "./resources/crm_phone_numbers";
import {CrmEmailsCreate} from "./resources/crm_emails";
import {CrmSystemsCreate} from "./resources/crm_systems";
import {
    CrmContractRelationshipCreate,
    CrmContractRelationshipList,
    CrmContractRelationshipShow
} from "./resources/crm_contract_relationships";
import {CtrPlanList, CtrPlanShow} from "./resources/ctr_plans";
import {CtrProductList} from "./resources/ctr_products";
import {
    CtrContractDraftCreate,
    CtrContractDraftList,
    CtrContractDraftShow,
    CtrContractDraftEdit,
    CtrContractDraftItemEdit,
    CtrContractDraftItemCreate
} from "./resources/ctr_contract_drafts";
import {CtrCancellationRequestCreate, CtrCancellationRequestList, CtrCancellationRequestShow, CtrCancellationRequestEdit} from "./resources/ctr_cancellation_requests";
import {
    CtrCancellationViewList,
    CtrCancellationViewShow
} from "./resources/ctr_cancellation_views";
import {CalcInvoiceItemExceptionList} from "./resources/calc_invoice_item_exceptions";
import {BexioCreditVoucherItemCreate, BexioCreditVoucherItemList} from "./resources/bexio_credit_voucher_items";
import {CtrSubscriptionViewList, CtrSubscriptionViewShow} from "./resources/ctr_subscription_views";
import {CtrCancellationReasonCreate, CtrCancellationReasonEdit} from "./resources/ctr_cancellation_reasons";
import {MonJobList} from "./resources/mon_job_logs";
import {CalcAccrualTransactionViewList} from "./resources/calc_accrual_transaction_views";
import {CalcInvoiceItemViewList, CalcInvoiceItemViewShow} from "./resources/calc_invoice_item_views";
import {CtrStageInvoiceList, CtrStageInvoiceShow} from "./resources/ctr_stage_invoices";
import {CtrStageMeteredInvoiceList, CtrStageMeteredInvoiceShow} from "./resources/ctr_stage_metered_invoices";
import {CtrInvoiceList, CtrInvoiceShow} from "./resources/ctr_invoices";
import {Group} from "@mui/icons-material";
import {StatisticValueViewList} from "./resources/statistic_value_views";
import MyLayout from "./components/MyLayout";
import MyLoginPage from "./components/MyLoginPage";
import { ENTRYPOINT } from "./config/entrypoint";
import {CtrContractList,CtrContractShow} from "./resources/ctr_contracts";
import { Route } from "react-router-dom";
import Profile from "./components/Profile";

const App = () => {
    const [_, setRedirectToLogin] = React.useState(false);
    return (
        <HydraAdmin
            layout={MyLayout}
            authProvider={authProvider}
            dataProvider={dataProvider(setRedirectToLogin)}
            entrypoint={ENTRYPOINT}
            theme={defaultTheme}
            loginPage={MyLoginPage}
        >
            {permissions => [
                <CustomRoutes>
                    <Route path={"/my-profile"} element={<Profile/>} />
                </CustomRoutes>,
                <Resource name="crm_contract_relationship_views" options={{label: 'Vertragsbeziehungen'}} list={CrmContractRelationshipList} show={CrmContractRelationshipShow}/>,
                <Resource name="crm_contract_relationships" options={{label: 'Vertragsbeziehungen'}} create={CrmContractRelationshipCreate}/>,
                <Resource name="ctr_subscription_views" options={{label: 'Abos'}} list={CtrSubscriptionViewList} show={CtrSubscriptionViewShow}/>,
                <Resource name="ctr_invoices" options={{label: 'Rechnungen'}} list={CtrInvoiceList} show={CtrInvoiceShow}/>,
                <Resource name="crm_venues" options={{label: 'Venues'}} list={CrmVenueList} show={CrmVenueShow} edit={CrmVenueEdit} create={CrmVenueCreate}/>,
                <Resource name="crm_systems" create={CrmSystemsCreate}/>,
                <Resource name="crm_groups" options={{label: 'Gastrogruppen'}} list={CrmGroupList} show={CrmGroupShow} edit={CrmGroupEdit} create={CrmGroupCreate}/>,
                <Resource name="crm_organisations" options={{label: 'Organisationen'}} list={CrmOrganisationList} show={CrmOrganisationShow} create={CrmOrganisationCreate} edit={CrmOrganisationEdit}/>,
                <Resource name="crm_addresses" create={CrmAddressesCreate}/>,
                <Resource name="crm_emails" create={CrmEmailsCreate}/>,
                <Resource name="crm_phone_numbers" create={CrmPhoneNumbersCreate}/>,
                <Resource name="ctr_products" options={{label: 'Produkte'}} list={CtrProductList}/>,
                <Resource name="ctr_plans" options={{label: 'Abopläne'}} list={CtrPlanList} show={CtrPlanShow}/>,
                <Resource name="ctr_contract_drafts" options={{label: 'Vertragsentwürfe'}} list={CtrContractDraftList} create={CtrContractDraftCreate} show={CtrContractDraftShow} edit={CtrContractDraftEdit}/>,
                <Resource name="ctr_contract_draft_items" create={CtrContractDraftItemCreate} edit={CtrContractDraftItemEdit}/>,
                <Resource name="ctr_stage_invoices" options={{label: 'Ratenrechnungsentwürfe'}} list={CtrStageInvoiceList} show={CtrStageInvoiceShow}/>,
                <Resource name="ctr_stage_metered_invoices" options={{label: 'Metered Rechnungsentwürfe'}} list={CtrStageMeteredInvoiceList} show={CtrStageMeteredInvoiceShow}/>,
                <Resource name="ctr_contracts" options={{label: 'Verträge'}} list={CtrContractList} show={CtrContractShow}/>,
                <Resource name="ctr_cancellation_requests" options={{label: 'Kündigungseingang'}} list={CtrCancellationRequestList} create={CtrCancellationRequestCreate} show={CtrCancellationRequestShow} edit={CtrCancellationRequestEdit}/>,
                <Resource name="ctr_cancellation_views" options={{label: 'Kündigungen'}} list={CtrCancellationViewList} show={CtrCancellationViewShow}/>,
                <Resource name="ctr_cancellation_reasons" create={CtrCancellationReasonCreate} edit={CtrCancellationReasonEdit}/>,
                permissions.includes('ROLE_LEDGER') ? <Resource name="calc_invoice_items"/> : null,
                permissions.includes('ROLE_LEDGER') ? <Resource name="calc_invoice_item_accruals"/> : null,
                permissions.includes('ROLE_LEDGER') ? <Resource name="calc_accrued_revenues"/> : null,
                permissions.includes('ROLE_LEDGER') ?
                    <Resource name="calc_accrual_transaction_views" options={{label: 'Abgrenzung - Buchungen'}}
                              list={CalcAccrualTransactionViewList}/> : null,
                permissions.includes('ROLE_LEDGER') ?
                    <Resource name="calc_invoice_item_views" options={{label: 'Abgrenzung - Rechnungen'}}
                              list={CalcInvoiceItemViewList} show={CalcInvoiceItemViewShow}/> : null,
                permissions.includes('ROLE_LEDGER') ?
                    <Resource name="bexio_credit_voucher_items" options={{label: 'Abgrenzung - Bexio Gutschriften'}} list={BexioCreditVoucherItemList} create={BexioCreditVoucherItemCreate}/> : null,
                permissions.includes('ROLE_LEDGER') ?
                    <Resource name="calc_invoice_item_exceptions" options={{label: 'Abgrenzung - Ausnahmeliste'}} list={CalcInvoiceItemExceptionList}/> : null,
                permissions.includes('ROLE_ADMIN') ?
                    <Resource name="opus21_users" list={UserList} edit={UserEdit} create={UserCreate} icon={Group}/> : null,
                permissions.includes('ROLE_LEDGER') || permissions.includes('ROLE_ADMIN') ?
                    <Resource name="mon_job_logs" options={{label: 'Prozesslog'}} list={MonJobList}/> : null,
                permissions.includes('ROLE_LEDGER') ? <Resource name="bexio_invoice_items"/> : null,
                <Resource name="conf_legal_forms"/>,
                <Resource name="conf_reporting_classes"/>,
                <Resource name="crm_entities"/>,
                <Resource name="ctr_contract_items"/>,
                <Resource name="ctr_contract_ledgers"/>,
                <Resource name="ctr_plan_items"/>,
                <Resource name="ctr_subscriptions"/>,
                permissions.includes('ROLE_PREVIEW') ?
                    <Resource name="statistic_value_views" list={StatisticValueViewList}/> : null,
            ]}
        </HydraAdmin>
    );
}

export default App
