import React from 'react';
import { useWatch } from 'react-hook-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import VenueQuickPreviewButton from './VenueQuickPreviewButton';

const VenueReferenceInput = props => {
    const venue = useWatch({name: 'crm_venue'});

    return (
        <>
            <ReferenceInput {...props} label="Venue" fullWidth >
                <AutocompleteInput
                    filterToQuery={searchText => ({ name: searchText })}
                    shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                    optionText="name" 
                    source="name"
                    optionValue="id"
                    fullWidth
                 />
            </ReferenceInput>
        
            {!!venue && <VenueQuickPreviewButton id={venue} />}
        </>
    );
};

export default VenueReferenceInput;
