import React, { useState } from 'react';
import {Drawer} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { Button, SimpleShowLayout, TextField, useGetOne, BooleanField, NumberField, DateField } from 'react-admin';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Visibility} from "@mui/icons-material";

const useStyles = makeStyles({
    field: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        '& span': {
            display: 'inline-block',
            maxWidth: '20em'
        }
    }
});

const PlanQuickPreviewButton = ({ id }) => {
    const [showPanel, setShowPanel] = useState();
    const classes = useStyles();
    const { data } = useGetOne('ctr_plans', { id: id });

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
        <>
            <Button onClick={handleClick} label="ra.action.show">
                <Visibility />
            </Button>
            <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
                <div>
                    <Button label="Close" onClick={handleCloseClick}>
                        <ChevronRightIcon />
                    </Button>
                </div>
                <SimpleShowLayout record={data}>
                    <TextField source="id" />
                    <TextField source="name" className={classes.field} />
                    <BooleanField source="automatic_renewal" />
                    <TextField source="automatic_renewal_period" />
                    <TextField source="cancellation" />
                    <NumberField source="notice_period_in_days" />
                    <TextField source="billing_period" />
                    <TextField source="terms_and_conditions" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <DateField source="created" />
                </SimpleShowLayout>
            </Drawer>
        </>
    );
};


export default PlanQuickPreviewButton;