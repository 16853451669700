import * as React from "react";
import {
    Show, SimpleShowLayout,
    TextInput,
    ReferenceManyField, FunctionField, NumberField,
    Datagrid, Link,
    TextField,
    DateField, BooleanField, RichTextField,
    List, Filter, TopToolbar, ListButton, useRecordContext, ReferenceField
} from 'react-admin';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { Button } from "react-admin";
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const AddNewPlanItemButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: "/ctr_plan_items/create",
            search: `?ctr_plan=${record ? record.originId : 1}`,
        }}
        label="Add a plan item"
    >
        <PlusOneIcon />
    </Button>
);

const CtrPlanTitle = () => {
    const record = useRecordContext();

    return <span>Plan {record ? `#${record.originId} - ${record.name}` : ''}</span>;
};

const CtrPlanFilter = (props) => (
    <Filter {...props}>
        <TextInput label="name" source="name" alwaysOn />
    </Filter>
)

const CtrPlanShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
    </TopToolbar>
);

export const CtrPlanList = props => (
    <List
        filters={<CtrPlanFilter />}
        title="Plan"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="name" headerClassName={useStyles().header} />
            <BooleanField source="automatic_renewal" headerClassName={useStyles().header} />
            <TextField source="automatic_renewal_period" headerClassName={useStyles().header} />
            <TextField source="cancellation" headerClassName={useStyles().header} />
            <NumberField source="notice_period_in_days" headerClassName={useStyles().header} />
            <TextField source="billing_period" headerClassName={useStyles().header} />
            <TextField source="terms_and_conditions" headerClassName={useStyles().header} />
            <DateField source="valid_from" headerClassName={useStyles().header} />
            <DateField source="valid_until" headerClassName={useStyles().header} />
            <DateField source="created" headerClassName={useStyles().header} />
        </Datagrid>
    </List>
);

export const CtrPlanShow = (props) => (
    <Show {...props} actions={<CtrPlanShowActions />} title={<CtrPlanTitle />}>
        <SimpleShowLayout>
            <TextField source="name" />
            <ReferenceManyField reference="ctr_plan_items" target="ctr_plan" source="id" label="Plan Items" sort={{field: 'id', order:'asc'}}>
                <Datagrid bulkActionButtons={false} >
                    <TextField label={"Id"} source="originId" />
                    <ReferenceField
                        reference="crm_product"
                        source="current_venue_address"
                        label="Addresszeile 1"
                        link={false}
                        sortable={false}
                        headerClassName={useStyles().header}
                    >
                        <TextField source="address_line1"  />
                    </ReferenceField>
                    <TextField source="name" />
                    <FunctionField label="price" render={record => (record.price/100).toFixed(2)} />
                    <FunctionField label="rebate" render={record => (record.rebate/100).toFixed(2)} />
                    <FunctionField label="rebate_percent" render={record => (record.rebate_percent/100).toFixed(2)} />
                    <NumberField source="number_of_units" />
                    <TextField source="unit" />
                    <NumberField source="first_installment" />
                    <NumberField source="last_installment" />
                    <NumberField source="contractual_installments" />
                    <RichTextField source="text_template" />
                    <DateField source="valid_from" />
                    <DateField source="valid_until" />
                    <DateField source="created" />
                    <DateField source="modified" />
                </Datagrid>
            </ReferenceManyField>
            <BooleanField source="automatic_renewal" />
            <TextField source="automatic_renewal_period" />
            <NumberField source="notice_period_in_days" />
            <TextField source="billing_period" />
            <TextField source="terms_and_conditions" />
            <DateField source="valid_from" />
            <DateField source="valid_until" />
            <DateField source="created" />
            <DateField source="modified" />
        </SimpleShowLayout>
    </Show>
);
