import { FILEPOINT } from "../config/entrypoint"
import authProvider from './authProvider'
const apiUrl = FILEPOINT;

const httpClient = (url, options={}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/pdf' });
    }
    // add your own headers here
    if (authProvider.getToken()) {
        options.headers.set('Authorization', `Bearer ${authProvider.getToken()}`);
    }
    return fetch(url, options)
}

const downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 1000);
};

const fileProvider =  {
    getFile: (mediaObject, fileName) => {
        return httpClient(`${apiUrl}${mediaObject}`, {
            method: 'GET',
        }).then((result) => result.arrayBuffer())
            .then((arrayBuffer) => {
            return new Blob([arrayBuffer], {type: 'application/pdf'})
        }).then((blob) => {
            if (!blob) {
                return;
            }
            downloadFile(blob, fileName);
        })
    }
};

export default fileProvider;
