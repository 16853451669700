import * as React from "react";
import {
    TextInput, SelectField, NumberField,
    Datagrid, TextField,BooleanInput,
    DateField, List, Filter, BooleanField
} from 'react-admin';
import { reportingClasses } from "./enums";
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CtrProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="name" source="name" alwaysOn />
        <TextInput label="account" source="account" alwaysOn />
        <BooleanInput label="isSubscription" source="is_subscription" />
        <BooleanInput label="isOrderForm" source="is_order_form" />
    </Filter>
)

export const CtrProductList = (props) => (
    <List
        filters={<CtrProductFilter />}
        title="Produkte"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="name" headerClassName={useStyles().header} />
            <TextField source="account" headerClassName={useStyles().header} />
            <SelectField source="conf_reporting_class" sortable={false} choices={reportingClasses} headerClassName={useStyles().header} />
            <BooleanField source="is_subscription" sortable={false} headerClassName={useStyles().header} />
            <BooleanField source="is_order_form" sortable={false} headerClassName={useStyles().header} />
            <DateField source="valid_from" headerClassName={useStyles().header} />
            <DateField source="valid_until" headerClassName={useStyles().header} />
            <DateField source="created" headerClassName={useStyles().header} />
            <DateField source="modified" headerClassName={useStyles().header} />
        </Datagrid>
    </List>
);
