import PlusOneIcon from '@mui/icons-material/PlusOne';
import {Button, Link, useRecordContext} from "react-admin";

const AddPhoneNumbersButton = ({ crmEntityId }) => {
    const record = useRecordContext();

    return <Link
        to={{
            pathname: "/crm_phone_numbers/create",
            search: `?crm_entity=${crmEntityId}&record_id=${record.id}`,
        }}
    >
        <Button label="Add a phone number"
                onClick={(e) => e.stopPropagation()}
        >
            <PlusOneIcon/>
        </Button>
    </Link>
}

export default AddPhoneNumbersButton;
