import {useNotify, Button} from 'react-admin';
import processProvider from "../utils/processProvider";
import {useNavigate} from "react-router-dom";

const PrepareRenewalsAndInstallmentsButton = () => {
    const navigate = useNavigate();
    const notify = useNotify();
    const handleClick = () => {
        processProvider.triggerProcess(`prepare_renewals_and_installments`, {})
            .then(() => {
                notify('Success: renewals and installments prepared.');
            })
            .catch(() => {
                notify('Error: preparation of renewals and installments failed.', 'warning')
            });
        navigate("/mon_job_logs");
    }

    return <Button
        onClick={handleClick}
        label="Erneuerungen und Ratenrechnungen generieren">
    </Button>
};


export default PrepareRenewalsAndInstallmentsButton;