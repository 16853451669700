import * as React from "react";
import {
    Filter,
    TextInput,
    List,
    TextField,
    Datagrid,
    DateField,
    NumberInput,
    NumberField,
    FunctionField
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import {statisticNames} from './enums';
import OpusPagination from "../components/OpusPagination";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const StatisticValueViewFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ReportingClass" source="reporting_class" alwaysOn  />
        <TextInput label="ReportingGroup" source="reporting_group" alwaysOn />
        <TextInput label="ReportingSection" source="reporting_section" alwaysOn />
        <NumberInput label="Account" source="account" alwaysOn />
        <TextInput label="Produkt" source="product" alwaysOn />
        <TextInput label="Währung" source="currency" alwaysOn />
        <TextInput label="Statistik" source="statistic_name" alwaysOn />
     </Filter>
);

const divider = record => {
    if (100 === statisticNames.filter(v => v.name === record.statistic_name)[0]['divisor']) {
        return (record.statistic_value / 100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    } else {
        return record.statistic_value.toLocaleString('de-ch', {minimumFractionDigits: 0, maximumFractionDigits: 0})
    }
}

export const StatisticValueViewList = props => (
    <List
        filters={<StatisticValueViewFilter />}
        title="Statistiken"
        pagination={<OpusPagination />}
        perPage={100}
        {...props}
    >
        <Datagrid  rowClick="show" bulkActionButtons={false} >
            <DateField source="month" headerClassName={useStyles().header} />
            <TextField source="reporting_class" headerClassName={useStyles().header} />
            <TextField source="reporting_group" headerClassName={useStyles().header} />
            <TextField source="reporting_section" headerClassName={useStyles().header} />
            <NumberField source="account" headerClassName={useStyles().header} />
            <TextField source="product" headerClassName={useStyles().header} />
            <TextField source="statistic_name" headerClassName={useStyles().header} />
            <TextField source="currency" headerClassName={useStyles().header} />
            <FunctionField label="statistic_value" render={record => divider(record)} headerClassName={useStyles().header} textAlign="right"/>
        </Datagrid>
    </List>
);