import React from 'react';
import {useWatch} from 'react-hook-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import GroupQuickPreviewButton from './GroupQuickPreviewButton';

const GroupReferenceInput = props => {
    const group = useWatch( {name: 'crm_group' } );

    return (
        <>
            <ReferenceInput {...props} label="Gastrogruppe" fullWidth >
                <AutocompleteInput
                    filterToQuery={searchText => ({ name: searchText })}
                    shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                    optionText="name"
                    optionValue="id"
                    source="name"
                    fullWidth
                />  
            </ReferenceInput>

            {!!group && <GroupQuickPreviewButton id={group} />}
        </>
    );
};

export default GroupReferenceInput;
