import CancelIcon from '@mui/icons-material/Cancel';
import {Button, Link, useRecordContext} from "react-admin";

const CancellationButton = () => {
    const record = useRecordContext();
    if (record.end_date) { return null; }
    const target = (record) => {
        if (record.outstanding_cancellation_request) {
            return {
                pathname: "/ctr_cancellation_requests",
            }
        } else {
            return {
                pathname: "/ctr_cancellation_requests/create",
                search: `?ctr_subscription_id=${record.id.replace('_view','')}&crm_contract_relationship_id=${record.crm_contract_relationship_id}&product=${record.product}`,
            }
        }
    }
    const label = () => {
        if (record.outstanding_cancellation_request) {
            return 'gekündigt';
        } else {
            return 'Abo beenden';
        }
    }

    return <Link to={target(record)}>
        <Button
            label={label()}
            onClick={(e) => e.stopPropagation()}
        >
            <CancelIcon />
        </Button>
    </Link>
};

export default CancellationButton;
