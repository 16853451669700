import React from 'react';
import {useNotify, useRecordContext} from 'react-admin';
import {Button} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import processProvider from "../utils/processProvider";
import { useRefresh } from 'react-admin';

const BexioSendButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = (e) => {
        e.stopPropagation()
        processProvider.bexioSend(record.id.slice(18))
            .then(() => {
                notify('email sent successfully')
                refresh()
            })
            .catch((e) => {
                notify('Error: email send unsuccessful', 'warning')
            })

    }

    return record.sendable && <Button variant="contained" size="small" endIcon={<SendIcon />} onClick={handleClick}>Bexio</Button>;
}

export default BexioSendButton;
