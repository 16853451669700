import * as React from "react";
import {
    FunctionField,
    NumberField,
    Datagrid,
    TextField,
    DateField,
    List
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";



const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

export const CalcInvoiceItemExceptionList = props => (
    <List
        title="Ausnahmen für Rechnungspositionen für die Berechnung der Abgrenzungen"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show"  >
            <TextField source="crm_contract_relationship_id" headerClassName={useStyles().header}/>
            <TextField source="ctr_product_id" headerClassName={useStyles().header}/>
            <TextField source="account" headerClassName={useStyles().header}/>
            <TextField source="currency" label="Währung" headerClassName={useStyles().header}/>
            <FunctionField label="amount" render={record => (record.amount/100).toFixed(2)} headerClassName={useStyles().header}/>
            <TextField source="invoice_id" label="Rechnungsnummer" headerClassName={useStyles().header}/>
            <TextField source="system_name" headerClassName={useStyles().header}/>
            <DateField source="invoice_month" headerClassName={useStyles().header} options={{ year: 'numeric', month: 'numeric' }}/>
            <DateField source="start_month" headerClassName={useStyles().header} options={{ year: 'numeric', month: 'numeric' }}/>
            <DateField source="end_month" headerClassName={useStyles().header} options={{ year: 'numeric', month: 'numeric' }}/>
            <NumberField source="month_count" headerClassName={useStyles().header}/>
            <TextField source="exception_comment" headerClassName={useStyles().header}/>
        </Datagrid>
    </List>
);





