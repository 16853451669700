import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput, useNotify,
    DateInput, SelectInput,
    required, useRedirect
} from 'react-admin';
import { systemNames } from "./enums";
import {useSearchParams} from "react-router-dom";

export const CrmSystemsCreate = (props) => {
    const [searchParams] = useSearchParams();
    const redirect = useRedirect();
    let crm_entity = searchParams.get('crm_entity');
    const record_id = searchParams.get('record_id');
    const redirectTo = record_id ? record_id.slice(4,record_id.indexOf('/',5))+'/'+encodeURIComponent(record_id)+'/show' : '/crm_contract_relationship_views';
    const notify = useNotify();
    const onSuccess = () => {
        notify(`Changes saved`);
        redirect(redirectTo);
    };
    return (
        <Create
            {...props}
            mutationOptions={{
                onSuccess: onSuccess,
            }}        >
            <SimpleForm
                defaultValues={{ crm_entity: crm_entity, source: 'admin' }}>
                <TextInput disabled source="crm_entity" />
                <SelectInput source="system_name" choices={systemNames} validate={[required()]}/>
                <TextInput label="id" source="system_id" />
                <DateInput source="valid_from" validate={[required()]}/>
                <DateInput source="valid_until" />
            </SimpleForm>
        </Create>);
}



