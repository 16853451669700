import PlusOneIcon from '@mui/icons-material/PlusOne';
import {Button, Link, useRecordContext} from "react-admin";

const AddNewContractDraftItemButton = () => {
        const record = useRecordContext();

        return <Link
            to={{
                pathname: "/ctr_contract_draft_items/create",
                search: `?record_id=${record.id}`,
            }}
        >
            <Button
                label="Add a contract item"
                onClick={(e) => e.stopPropagation()}
            >
                <PlusOneIcon/>
            </Button>
        </Link>
    }
;

export default AddNewContractDraftItemButton;
