import React, { useState, useEffect } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import processProvider from '../utils/processProvider'
import {useCreate, useNotify, useRedirect} from 'react-admin'
import {countries, legalForms} from "../resources/enums";
import {Button, FormControl, InputLabel} from "@mui/material";

const MyForm = () => {
    const [formState, setFormState] = useState({
        legal_name: '',
        uid: '',
        country: 'CHE',
        legal_form: '',
        invoice_preference: 'letter'
    })

    const redirect = useRedirect()
    const notify = useNotify()
    const [autocompleteOptions, setAutocompleteOptions] = useState([])
    const [doSearch, setDoSearch] = useState(true)
    const [create, { isLoading, error }] = useCreate();
    const [isDisabled, setIsDisabled] = useState(true);
    const disableButton = (formState) => isLoading ||
        (!formState.legal_name || !formState.legal_form || !formState.country || !formState.invoice_preference)

    useEffect(() => {
        async function fetchOptions() {
            // Fetch the options for the autocomplete field from the API
            const response = await processProvider.searchUid({search_string: formState.legal_name})
            setAutocompleteOptions((prevState) => response.data.organisations)
        }

        // Fetch options only when the user stops typing for 500ms and has entered at least 4 characters
        if (doSearch && formState && formState.legal_name.length >= 4) {
            const timeoutId = setTimeout(() => {
                setDoSearch(false)
                fetchOptions()
            }, 500)
            return () => {
                clearTimeout(timeoutId)
            };
        }
    }, [formState, doSearch])

    const handleInputChange = (event) => {
        const { name, value } = event.target
        const newState = { ...formState, [name]: value }
        setFormState(newState)
        setIsDisabled(disableButton(newState))
        setDoSearch(prevState => 'legal_name' === name || prevState)
    }

    const handleAutocompleteChange = (event, value) => {
        try {
            const newState = {
                ...formState,
                legal_name: value.label,
                uid: value.uid,
                country: value.uid.substring(0, 3),
                legal_form: '/api/conf_legal_forms/'+value.legal_form
            }
            setFormState(newState)
            setIsDisabled(disableButton(newState))
        } catch {
            setAutocompleteOptions(prevState => [])
            const fallbackState = {...formState, legal_name: ''}
            setFormState(fallbackState)
            setIsDisabled(disableButton(fallbackState))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        create(
            'crm_organisations',
            { data: {...formState} },
            {
                onSuccess: () => {
                    notify(
                        'resources.crm_organisations.notification.saved_success',
                        'info',
                        {},
                        true
                    );
                    redirect('/crm_organisations?sort=id&order=DESC');
                },
                onFailure: () =>
                    notify(
                        'resources.crm_organisations.notification.saved_error',
                        'warning'
                    ),
            }
        )
    }

    return (
        <form onSubmit={handleSubmit} >
            <FormControl fullWidth={true} required={true}>
                <InputLabel id="legal-name-autocomplete-label">Firma</InputLabel>
                <Autocomplete
                    labelId="legal-name-autocomplete-label"
                    options={autocompleteOptions.map(option => ({...option, id: option.uid, label: option.legal_name || option.name}))}
                    freeSolo
                    onChange={handleAutocompleteChange}
                    renderOption={(props, option) =><li {...props} key={option.id}>{option.name}</li>}
                    renderInput={(params) => {
                        return <TextField
                            {...params}
                            name="legal_name"
                            onChange={handleInputChange}
                        />
                    }}
                />
            </FormControl>
            <FormControl fullWidth={true} >
                <InputLabel id="uid-text-label">Uid</InputLabel>
                <TextField
                    name="uid"
                    labelId="uid-text-label"
                    value={formState.uid}
                    onChange={handleInputChange}
                />
            </FormControl>
            <FormControl fullWidth={true} required={true}>
                <InputLabel id="country-select-label">Domizil</InputLabel>
                <Select
                    margin="normal"
                    name="country"
                    labelId="country-select-label"
                    value={formState.country}
                    onChange={handleInputChange}
                >
                    {countries.map((item) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl fullWidth={true} required={true}>
                <InputLabel id="legal_form-select-label">Rechtsform</InputLabel>
                <Select
                    name="legal_form"
                    label="legal_form-select-label"
                    value={formState.legal_form}
                    onChange={handleInputChange}
                >
                    {legalForms.map((item) => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl fullWidth={true} required={true}>
                <InputLabel id="invoice_preference-select-label">Rechnungsversand</InputLabel>
                <Select
                    labelId="invoice_preference-select-label"
                    id="invoice_preference-select"
                    name="invoice_preference"
                    value={formState.invoice_preference}
                    onChange={handleInputChange}
                >
                    <MenuItem value="letter" key="letter">Post (Pingen)</MenuItem>
                    <MenuItem value="email" key="email">Email</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                    <Button type="submit" variant="contained" disabled={isDisabled}>Save</Button>
            </FormControl>
        </form>
    )
}

export default MyForm
