import * as React from "react";
import {
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    SimpleForm,
    TextInput,
    required,
    NumberField,
    Datagrid,
    TextField,
    FormTab,
    TabbedForm,
    useNotify,
    useRedirect,
    List,
    Filter,
    TopToolbar,
    ListButton,
    ShowButton,
    EditButton,
    FunctionField,
    ReferenceManyField,
    BooleanField,
    DateField, useRecordContext
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";
import CancellationButton from "../components/CancellationButton";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CrmGroupTitle = () => {
    const record = useRecordContext();

    return <span>Gastrogruppe {record ? `#${record.originId} - ${record.name}` : ''}</span>;
};

const CrmGroupFilter = (props) => (
    <Filter {...props}>
        <TextInput label="name" source="name" alwaysOn />
    </Filter>
)

const CrmGroupShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <EditButton />
    </TopToolbar>
);

const CrmGroupEditActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <ShowButton />
    </TopToolbar>
);

export const CrmGroupList = props => (
    <List
        filters={<CrmGroupFilter />}
        title="Gastrogruppe"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <TextField source="name" headerClassName={useStyles().header} />
            <FunctionField label="verrechnet" render={record => (record.invoice_statistic.invoice_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <FunctionField label="bezahlt" render={record => (record.invoice_statistic.paid_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <FunctionField label="offen" render={record => (record.invoice_statistic.open_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const CrmGroupCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = () => {
        redirect('list', props.basepath);
    }

    const onSuccess = () => {
        notify('Gastrogruppe saved successfully');
        redirect('list', props.basepath);
    }

    return (
        <Create mutationMode="pessimistic" onSuccess={onSuccess} onFailure={onFailure} {...props} >
            <TabbedForm>
                <FormTab label="Gastrogruppe">
                    <TextInput source="name" validate={[required()]} />
                </FormTab>
            </TabbedForm>
        </Create>
    );
}

export const CrmGroupShow = () => (
    <Show actions={<CrmGroupShowActions />} title={<CrmGroupTitle />}>
        <SimpleShowLayout>
            <TextField source="name" />
            <FunctionField label="verrechnet" render={record => (record.invoice_statistic.invoice_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <FunctionField label="bezahlt" render={record => (record.invoice_statistic.paid_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <FunctionField label="offen" render={record => (record.invoice_statistic.open_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
            <ReferenceManyField
                reference="crm_contract_relationship_views"
                target="gastrogruppe"
                source="name"
                label="Vertragsbeziehungen"
                sort={{field: 'id', order:'asc'}}
                perPage={100}
            >
                <Datagrid >
                    <FunctionField label="Vertragsbeziehung" render={record => CrmContractRelationshipIdField(record.originId)} headerClassName={useStyles().header} sortable={false} />
                    <TextField source="venue" label="Venue" headerClassName={useStyles().header} />
                    <TextField source="firma" label="Organisation" headerClassName={useStyles().header} />
                    <TextField source="city" label="Ort" headerClassName={useStyles().header} />
                    <FunctionField label="verrechnet" render={record => (record.invoice_statistic.invoice_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
                    <FunctionField label="bezahlt" render={record => (record.invoice_statistic.paid_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
                    <FunctionField label="offen" render={record => (record.invoice_statistic.open_amount/100).toLocaleString('de-ch', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header} textAlign="right"/>
                </Datagrid>
            </ReferenceManyField>
            <ReferenceManyField reference="ctr_subscription_views" label="Abos" target="gastrogruppe" source="name" sort={{ field: 'end_date', order: 'DESC' }} perPage={100} >
                <Datagrid>
                    <TextField label="Abo" source="originId" headerClassName={useStyles().header} />
                    <FunctionField label="Vertragsbeziehung" render={record => CrmContractRelationshipIdField(record.crm_contract_relationship_id)} headerClassName={useStyles().header} sortable={false} />
                    <TextField source="venue" label="Venue" headerClassName={useStyles().header} />
                    <TextField source="firma" label="Organisation" headerClassName={useStyles().header} />
                    <TextField source="product" headerClassName={useStyles().header} />
                    <BooleanField source="automatic_renewal" headerClassName={useStyles().header} />
                    <TextField source="automatic_renewal_period" headerClassName={useStyles().header} />
                    <TextField source="cancellation" headerClassName={useStyles().header} />
                    <TextField source="notice_period_in_days" headerClassName={useStyles().header} />
                    <TextField source="billing_period" headerClassName={useStyles().header} />
                    <TextField source="terms_and_conditions" headerClassName={useStyles().header} />
                    <DateField source="start_date" headerClassName={useStyles().header} />
                    <DateField source="billed_until_date" headerClassName={useStyles().header} />
                    <DateField source="contract_until_date" headerClassName={useStyles().header} />
                    <DateField source="end_date" headerClassName={useStyles().header} />
                    <FunctionField render={record => <CancellationButton record={record} />}/>
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

export const CrmGroupEdit = (props) => {
    return (
        <Edit mutationMode="pessimistic" {...props} actions={<CrmGroupEditActions />} undoable="false" title={<CrmGroupTitle />}>
            <SimpleForm>
                <TextInput source="name" />
            </SimpleForm>
        </Edit>);
}
