import * as React from "react";
import {
    Show,
    List,
    SimpleShowLayout,
    TextField,
    Datagrid,
    DateField,
    RichTextField,
    FunctionField,
    NumberField,
    BooleanField,
    TopToolbar,
    ListButton,
    Filter, TextInput, SelectInput, ReferenceField, ReferenceManyField
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import OpusPagination from "../components/OpusPagination";
import {ctrCurrency} from "./enums";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CtrContractFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Kundennummer" source="crm_contract_relationship.id" alwaysOn  />
        <TextInput label="Venue" source="crm_contract_relationship.crm_venue.name" alwaysOn />
        <TextInput label="Firma" source="crm_contract_relationship.crm_organisation.legal_name" alwaysOn />
        <TextInput label="Gastrogruppe" source="crm_contract_relationship.crm_group.name" alwaysOn />
        <SelectInput label="Währung" source={"currency"} choices={ctrCurrency} alwaysOn />
    </Filter>
);

const CtrContractTitle = ({ record }) => {
    return <span>Contract {record ? `#${record.id} - ${record.name}` : ''}</span>;
};

const CtrContractShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
    </TopToolbar>
);

export const CtrContractList = props => (
    <List
        title="Contract"
        pagination={<OpusPagination />}
        perPage={10}
        filters={<CtrContractFilter/>}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Vertragsbeziehung" headerClassName={useStyles().header} link={false} >
                <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Venue" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_venues" source="crm_venue" link={false}><TextField source="name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Organisation" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_organisations" source="crm_organisation" link={false}><TextField source="legal_name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                <FunctionField render={record => record.crm_group && <ReferenceField reference="crm_groups" source="crm_group" link={false}><TextField source="name"/></ReferenceField>} />
            </ReferenceField>
            <TextField source="currency" headerClassName={useStyles().header} sortable={false} />
            <FunctionField label="Betrag" render={record => (record.contract_total/100).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2})} headerClassName={useStyles().header}/>
            <DateField source="start_date" headerClassName={useStyles().header} />
            <BooleanField source="automatic_renewal" headerClassName={useStyles().header} />
            <TextField source="automatic_renewal_period" headerClassName={useStyles().header} />
            <TextField source="billing_period" headerClassName={useStyles().header} />
            <DateField source="created" headerClassName={useStyles().header} />
        </Datagrid>
    </List>
);

export const CtrContractShow = (props) => (
    <Show {...props} title={<CtrContractTitle />} actions={<CtrContractShowActions/>}>
        <SimpleShowLayout>
            <ReferenceField source="crm_contract_relationship" label="Vertragsbeziehung" headerClassName={useStyles().header} link={false} >
                <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
            </ReferenceField>
            <ReferenceField source="crm_contract_relationship" label="Organisation" headerClassName={useStyles().header} link={false} >
                <ReferenceField source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField source="crm_contract_relationship" label="Venue" headerClassName={useStyles().header} link={false} >
                <ReferenceField source="crm_venue"  link={false}><TextField source="name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField source="crm_contract_relationship" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                <FunctionField render={record => record.crm_group && <ReferenceField source="crm_group"  link={false}><TextField source="name"/></ReferenceField>} />
            </ReferenceField>
            <FunctionField  label="Rechnungsadresse" render={record => {
                if(!record.billing_address) {
                    return <div><strong>!!!!! RECHNUNGSADRESSE MUSS NOCH ERFASST WERDEN !!!!!</strong></div>
                } else {
                    return <ReferenceField reference="crm_addresses" source="billing_address" link={false} >
                        <TextField source="address_line1"/>{',' }<TextField source="address_line2"/>{', '}<TextField source="zip"/>{', '}<TextField source="city"/>{', '}<TextField source="country"/>
                    </ReferenceField>}
            }
            }/>
            <ReferenceManyField
                reference="ctr_contract_items"
                target="ctr_contract"
                source="id"
                label="Items"
                sort={{field: 'id', order:'asc'}}
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField label="Id" source="originId" />
                    <TextField source="installment" />
                    <BooleanField source="is_contractual" />
                    <ReferenceField source="ctr_product" reference="ctr_products" label="Produkt" link={false} fullWidth >
                        <TextField source="name"/>
                    </ReferenceField>
                    <FunctionField label="total" render={record => (record.position_total/100).toFixed(2)} />
                    <FunctionField label="price" render={record => (record.price/100).toFixed(2)} />
                    <FunctionField label="rebate" render={record => (record.rebate/100).toFixed(2)} />
                    <FunctionField label="rebate_percent" render={record => (record.rebate_percent/1000000).toFixed(2)} />
                    <NumberField source="number_of_units" />
                    <TextField source="unit" />
                    <RichTextField source="item_text" />
                    <DateField source="start_date" />
                    <DateField source="end_date" />
                    <DateField source="created" />
                    <DateField source="modified" />
                </Datagrid>
            </ReferenceManyField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Venue" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_venues" source="crm_venue" link={false}><TextField source="name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Organisation" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_organisations" source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
            </ReferenceField>
            <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                <FunctionField render={record => record.crm_group && <ReferenceField reference="crm_groups" source="crm_group"  link={false}><TextField source="name"/></ReferenceField>} />
            </ReferenceField>
             <TextField source="ctr_plan" headerClassName={useStyles().header} />
            <DateField source="start_date" />
            <BooleanField source="automatic_renewal" />
            <TextField source="automatic_renewal_period" />
            <TextField source="cancellation" />
            <NumberField source="notice_period_in_days" />
            <TextField source="billing_period" />
            <TextField source="terms_and_conditions" />
            <TextField source="prepared_by" />
            <RichTextField source="notes" />
            <DateField source="prepared" />
        </SimpleShowLayout>
    </Show>
);
    