import React from 'react';
import {useNotify, useRecordContext} from 'react-admin';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import processProvider from "../utils/processProvider";

const ApproveButton = ( {action, entity} ) => {
    const record = useRecordContext();
    const notify = useNotify();
    const navigate = useNavigate();
    const navigateTo = (entity) => {
        if (entity === 'contract_draft') {
            return '/ctr_contract_drafts/';
        } else if (entity === 'cancellation_request') {
            return '/ctr_cancellation_requests/';
        } else if (entity === 'ctr_stage_metered_invoice') {
            return '/ctr_stage_metered_invoices/';
        }
        return '/ctr_stage_invoices/';
    }
    const idValue = (action, record) => {
        const id = record.id.slice(record.id.indexOf('/',5)+1)
        return action === 'invoice_usage' ? '"'+id+'"' : id
    }

    const handleClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (action === 'cancel' || action === 'invoice_usage') {
            const payload = '{ "entity": "'+entity+'", "id": '+idValue(action, record)+'}';
            const entry = { message_version: 1, subscription_action: action, payload: payload }
            processProvider.createSubscriptionLedger({data: entry})
                .then(() => {
                    notify(entity+' approved');
                })
                .catch((e) => {
                    notify('Error: '+entity+' not approved', 'warning')
                });
        } else if (action === 'create' || action === 'invoice_installment') {
            const payload = '{ "entity": "'+entity+'", "'+entity+'": '+idValue(action, record)+'}';
            const entry = { action: action, payload: payload, comment: "approval button in opus21backend" }
            processProvider.createContractLedger({data: entry})
                .then(() => {
                    notify(entity+' approved');
                })
                .catch((e) => {
                    notify('Error: '+entity+' not approved', 'warning')
                });
        }
        navigate(navigateTo(entity));
    }

    return <Button onClick={handleClick}> <ThumbUpAltOutlinedIcon /></Button>;
}

export default ApproveButton;
