import * as React from "react";
import {
    TextInput,
    SimpleShowLayout,
    NumberField,
    Show,
    Datagrid,
    TextField,
    BooleanInput,
    DateField,
    List,
    Filter,
    FunctionField,
    TopToolbar,
    ListButton,
    BooleanField,
    RichTextField, useRecordContext, ReferenceField, ReferenceManyField
} from 'react-admin';
import {makeStyles} from "@mui/styles";
import CrmContractRelationshipIdField from "../components/CrmContractRelationshipIdField";
import OpusPagination from "../components/OpusPagination";
import ApproveButton from "../components/ApproveButton";

const useStyles = makeStyles({
    total: {
        fontWeight: 'bold',
    },
    header: {
        fontWeight: 'bold',
    }
});

const CtrStageInvoiceTitle = () => {
    const record = useRecordContext();

    return <span>Ratenrechnung {record ? `#${record.originId}` : '' }</span>
};

const CtrStageInvoiceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="name" source="name" alwaysOn />
        <TextInput label="account" source="account" alwaysOn />
        <BooleanInput label="isSubscription" source="isSubscription" />
        <BooleanInput label="isOrderForm" source="isOrderForm" />
    </Filter>
);

const ApproveInvoiceButton = () => <ApproveButton entity="ctr_stage_invoice_id" action="invoice_installment"/>

const CtrStageInvoiceShowActions = () => (
    <TopToolbar>
        <ListButton label="Back" />
        <ApproveInvoiceButton/>
    </TopToolbar>
);

export const CtrStageInvoiceList = (props) => (
    <List
        filters={<CtrStageInvoiceFilter />}
        filter={{ 'deleted[after]': '2200-12-31' }}
        title="Ratenrechnungen"
        pagination={<OpusPagination />}
        perPage={25}
        {...props}
    >
        <Datagrid rowClick="show" bulkActionButtons={false} >
            <NumberField label="Id" source="originId" headerClassName={useStyles().header} sortBy="id" />
            <ReferenceField reference="ctr_contracts" source="ctr_contract" label="Vertragsbeziehung" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField reference="ctr_contracts" source="ctr_contract" label="Venue" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <ReferenceField reference="crm_venues" source="crm_venue" link={false}><TextField source="name"/></ReferenceField>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField reference="ctr_contracts" source="ctr_contract" label="Organisation" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <ReferenceField reference="crm_organisations" source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField reference="ctr_contracts" source="ctr_contract" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                <ReferenceField reference="crm_contract_relationships" source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <FunctionField render={record => record.crm_group && <ReferenceField reference="crm_groups" source="crm_group" link={false}><TextField source="name"/></ReferenceField>} />
                </ReferenceField>
            </ReferenceField>
            <FunctionField
                render={record => record.ctr_plan && <ReferenceField reference="ctr_plans" source="ctr_plan" link={false} ><TextField source="name"/></ReferenceField>}
                headerClassName={useStyles().header}
                label="Plan"
            />
            <TextField source="prepared_by" headerClassName={useStyles().header} />
            <RichTextField source="notes" headerClassName={useStyles().header} />
            <DateField source="prepared" headerClassName={useStyles().header} />
            <DateField source="created" headerClassName={useStyles().header} />
            <DateField source="modified" headerClassName={useStyles().header} />
            <ApproveInvoiceButton/>
        </Datagrid>
    </List>
);

export const CtrStageInvoiceShow = () => (
    <Show
        title={<CtrStageInvoiceTitle />}
        actions={<CtrStageInvoiceShowActions/>}
    >
        <SimpleShowLayout title="Ratenrechnungen">
            <ReferenceField source="ctr_contract" label="Vertragsbeziehung" headerClassName={useStyles().header} link={false} >
                <ReferenceField source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <FunctionField render={record => CrmContractRelationshipIdField(record.originId)}  headerClassName={useStyles().header}/>
                </ReferenceField>
            </ReferenceField>
            <ReferenceManyField
                reference="ctr_stage_invoice_items"
                target="ctr_stage_invoice"
                source="id"
                label="Positionen"
                headerClassName={useStyles().header}
                link={false}
            >
                <Datagrid bulkActionButtons={false}>
                     <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="installment" link={false} >
                        <TextField source="installment"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="is_contractual" link={false} >
                        <BooleanField source="is_contractual"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="Produkt"  link={false} >
                        <ReferenceField reference="ctr_products" source="ctr_product" link={false}>
                            <TextField source="name"/>
                        </ReferenceField>
                    </ReferenceField>
                     <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="price" link={false} >
                        <FunctionField render={record => (record.price / 100).toFixed(2)}/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="rebate" link={false} >
                        <FunctionField render={record => (record.rebate / 100).toFixed(2)}/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="rebate_percent" link={false} >
                        <FunctionField render={record => (record.rebate_percent / 1000000).toFixed(2)}/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="number_of_units" link={false} >
                        <NumberField source="number_of_units"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="unit" link={false} >
                        <TextField source="unit"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="item_text" link={false} >
                        <RichTextField source="item_text"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="start_date" link={false} >
                        <DateField source="start_date"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="end_date" link={false} >
                        <DateField source="end_date"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="end_date" link={false} >
                        <DateField source="created"/>
                    </ReferenceField>
                    <ReferenceField reference="ctr_contract_items" source="ctr_contract_item" label="end_date" link={false} >
                        <DateField source="modified"/>
                    </ReferenceField>
                </Datagrid>
            </ReferenceManyField>
            <ReferenceField source="ctr_contract" label="Venue" headerClassName={useStyles().header} link={false} >
                <ReferenceField source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <ReferenceField source="crm_venue"  link={false}><TextField source="name"/></ReferenceField>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="Organisation" headerClassName={useStyles().header} link={false} >
                <ReferenceField source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <ReferenceField source="crm_organisation"  link={false}><TextField source="legal_name"/></ReferenceField>
                </ReferenceField>
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="Gastrogruppe" headerClassName={useStyles().header} link={false} >
                <ReferenceField source="crm_contract_relationship" headerClassName={useStyles().header} link={false} >
                    <FunctionField render={record => record.crm_group && <ReferenceField source="crm_group"  link={false}><TextField source="name"/></ReferenceField>} />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="Plan" headerClassName={useStyles().header} link={false} >
                <FunctionField
                    render={record => record.ctr_plan && <ReferenceField source="ctr_plan" link={false} ><TextField source="name"/></ReferenceField>}
                    headerClassName={useStyles().header}
                    label="Plan"
                />
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="automatic renewal" headerClassName={useStyles().header} link={false} >
                <BooleanField source="automatic_renewal" headerClassName={useStyles().header} />
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="automatic renewal period" headerClassName={useStyles().header} link={false} >
                <TextField source="automatic_renewal_period" headerClassName={useStyles().header} />
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="cancellation" headerClassName={useStyles().header} link={false} >
                <TextField source="cancellation" headerClassName={useStyles().header} />
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="notice period in days" headerClassName={useStyles().header} link={false} >
                <NumberField source="notice_period_in_days" headerClassName={useStyles().header} />
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="billing period" headerClassName={useStyles().header} link={false} >
                <TextField source="billing_period" headerClassName={useStyles().header} />
            </ReferenceField>
            <ReferenceField source="ctr_contract" label="terms and conditions" headerClassName={useStyles().header} link={false} >
                <TextField source="terms_and_conditions" headerClassName={useStyles().header} />
            </ReferenceField>
            <TextField source="prepared_by" />
            <RichTextField source="notes" />
            <DateField source="prepared" />
            <ApproveInvoiceButton/>
        </SimpleShowLayout>
    </Show>
);
