import {PasswordInput, SimpleForm, TextInput, useGetList, Loading, required} from "react-admin";
import * as React from "react";
import authProvider from "../utils/authProvider";
import profileProvider from "../utils/profileProvider";

const Profile  = () => {
    const { data, isLoading, error } = useGetList(
        'opus21_users',
        {
            pagination: { page: 1, perPage: 1 },
            filter: { username: authProvider.getUsername() }
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const validate = (values) => {
        const errors = {};
        if(values.new !== values.dropped) {
            console.log('passwords do not match')
            errors.new = 'passwords do not match'
            errors.dropped = 'passwords do not match'
        }
        return errors
    }
    const onSubmit = (values) => {
        let params = data[0]
        params.plain_password = values.new
        params.current_password = values.password
        profileProvider.updateUserProfile(params)
        values.new = ''
        values.dropped = ''
        values.password = ''
    }

    return (
        <SimpleForm
            onSubmit={onSubmit}
            sanitizeEmptyValues
            validate={validate}
            sx={{ maxWidth: 1200}}
        >
            <TextInput
                source="username"
                disabled
                defaultValue={data[0].username}
                fullWidth
            />
            <PasswordInput
                source="password"
                label="current password"
                validate={[required()]}
                fullWidth
            />
            <PasswordInput
                source="new"
                label="new password"
                inputProps={{ autocomplete: false, defaultValue: "" }}
                validate={[required()]}
                fullWidth
            />
            <PasswordInput
                source="dropped"
                label="confirm new password"
                inputProps={{ autocomplete: false, defaultValue: "" }}
                validate={[required()]}
                fullWidth
            />
        </SimpleForm>
    )
}

export default Profile