import React from 'react';
import {useNotify, Button} from 'react-admin';
import processProvider from "../utils/processProvider";
import {useNavigate} from "react-router-dom";

const CalculateAccrualsButton = () => {
    const notify = useNotify()
    const navigate = useNavigate();
    const handleClick = () => {
        processProvider.triggerProcess(`calculate_accruals`, {})
            .then(() => {
                notify('Success: Accruals calculated');
            })
            .catch((e) => {
                notify('Error: Accrual calculation request was not processed.', 'warning')
            });
        navigate("/mon_job_logs");
    }

    return <Button
        onClick={handleClick}
        label="Abgrenzungen neu berechnen">
    </Button>
};


export default CalculateAccrualsButton;