import React from 'react';
import { useWatch } from 'react-hook-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import PlanQuickPreviewButton from './PlanQuickPreviewButton';

const PlanReferenceInput = props => {
    const ctr_plan = useWatch( {name: 'ctr_plan' } );

    return (
        <>
            <ReferenceInput
                {...props}
                label="Plan"
                fullWidth
            >
                <AutocompleteInput 
                    shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                    filterToQuery={searchText => ({ name: searchText, 'valid_until[after]': new Date().toJSON().slice(0,10) })}
                    optionText="name"
                    optionValue="id"
                    fullWidth
                />  
            </ReferenceInput>

            {!!ctr_plan && <PlanQuickPreviewButton id={ctr_plan} />}
        </>
    );
};

export default PlanReferenceInput;
