import React from 'react';
import { useWatch } from 'react-hook-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import OrganisationQuickPreviewButton from './OrganisationQuickPreviewButton';

const OrganisationReferenceInput = props => {
    const organisation = useWatch( {name: 'crm_organisation' } );

    return (
        <>
            <ReferenceInput
                {...props}
                label="Organisation"
                fullWidth
            >
                <AutocompleteInput 
                    shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                    filterToQuery={searchText => ({ legal_name: searchText })}
                    optionText="legal_name"
                    optionValue="id"
                    fullWidth
                />
            </ReferenceInput>

            {!!organisation && <OrganisationQuickPreviewButton id={organisation} />}
        </>
    );
};

export default OrganisationReferenceInput;
