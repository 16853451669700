import React from 'react';
import { useWatch } from 'react-hook-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import RelationshipQuickPreviewButton from './RelationshipQuickPreviewButton';

const RelationshipReferenceInput = props => {
    const relationship = useWatch( {name: 'crm_contract_relationship_id' } );

    return (
        <>
            <ReferenceInput
                {...props}
                label="Venue der Vertragsbeziehung"
                sx={{ width: 600 }}
           >
                <AutocompleteInput 
                    shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                    filterToQuery={searchText => ({ venue: searchText })}
                    optionText="venue"
                    source="venue"
                    fullWidth
                />  
            </ReferenceInput>

            {!!relationship && <RelationshipQuickPreviewButton id={relationship} />}
        </>
    );
};

export default RelationshipReferenceInput;
